import React from 'react';

import AdminHeader from "../admin-header/AdminHeader";

export default function Subscription() {
  return (
    <>
      <main className="main" id="top">
        {/*<nav class="navbar navbar-top navbar-slim fixed-top navbar-expand" id="topNavSlim">*/}
        <AdminHeader />
        <div className="content">
          <h2 className="fs-6 mb-5">Packages</h2>
          <div id="members">
            <div className="mx-n4 mx-lg-n6 px-4 px-lg-6 mb-9 bg-white border-y border-300 mt-2 position-relative top-1">
              <div className="table-responsive scrollbar ms-n1 ps-1">
                <table
                  className="table table-sm fs--1 mb-0"
                  style={{
                    fontFamily:
                      "SF Pro Text, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif",
                  }}
                >
                  <thead>
                    <tr>
                      <th
                        className="sort align-middle"
                        scope="col"
                        data-sort="sr"
                      >
                        No
                      </th>
                      <th
                        className="sort align-middle"
                        scope="col"
                        data-sort="customer"
                      >
                        Package
                      </th>
                      <th
                        className="sort align-middle"
                        scope="col"
                        data-sort="interest"
                      >
                        Time
                      </th>
                      <th
                        className="sort align-middle"
                        scope="col"
                        data-sort="interest"
                      >
                        Amount
                      </th>
                      <th
                        className="sort align-middle"
                        scope="col"
                        data-sort="customer"
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody className="list" id="members-table-body">
                    <tr className="hover-actions-trigger btn-reveal-trigger position-static">
                      <td className="sr align-middle white-space-nowrap">1</td>
                      <td className="customer align-middle white-space-nowrap">
                        Premuim Plan
                      </td>
                      <td className="interest align-middle white-space-nowrap">
                        00:10:00
                      </td>
                      <td className="interest align-middle white-space-nowrap">
                        200
                      </td>
                      <td>
                        <form
                          method="POST"
                          id="regForm"
                          action="confirmpurchase"
                        >
                          <input
                            type="hidden"
                            name="_token"
                            defaultValue="Zah9a2H5c9Lb6RQiFz5hiTc0UNPPJv2cGNmZHXqv"
                            autoComplete="off"
                          />
                          <input
                            type="hidden"
                            defaultValue={200}
                            name="amount"
                          />
                          <input
                            type="hidden"
                            defaultValue={7}
                            name="packageid"
                          />
                          <input
                            type="hidden"
                            defaultValue="Premuim Plan"
                            name="packagetitle"
                          />
                          <input
                            type="hidden"
                            defaultValue={600}
                            name="totaltime"
                          />
                          <i
                            className="fa fa-shopping-cart"
                            style={{ fontSize: 22 }}
                          />
                        </form>
                      </td>
                    </tr>
                    <tr className="hover-actions-trigger btn-reveal-trigger position-static">
                      <td className="sr align-middle white-space-nowrap">2</td>
                      <td className="customer align-middle white-space-nowrap">
                        Standard Plan
                      </td>
                      <td className="interest align-middle white-space-nowrap">
                        00:05:00
                      </td>
                      <td className="interest align-middle white-space-nowrap">
                        150
                      </td>
                      <td>
                        <form
                          method="POST"
                          id="regForm"
                          action="confirmpurchase"
                        >
                          <input
                            type="hidden"
                            name="_token"
                            defaultValue="Zah9a2H5c9Lb6RQiFz5hiTc0UNPPJv2cGNmZHXqv"
                            autoComplete="off"
                          />
                          <input
                            type="hidden"
                            defaultValue={150}
                            name="amount"
                          />
                          <input
                            type="hidden"
                            defaultValue={1}
                            name="packageid"
                          />
                          <input
                            type="hidden"
                            defaultValue="Standard Plan"
                            name="packagetitle"
                          />
                          <input
                            type="hidden"
                            defaultValue={300}
                            name="totaltime"
                          />
                          <i
                            className="fa fa-shopping-cart"
                            style={{ fontSize: 22 }}
                          />
                        </form>
                      </td>
                    </tr>
                    <tr className="hover-actions-trigger btn-reveal-trigger position-static">
                      <td className="sr align-middle white-space-nowrap">3</td>
                      <td className="customer align-middle white-space-nowrap">
                        Basic Plan
                      </td>
                      <td className="interest align-middle white-space-nowrap">
                        00:03:20
                      </td>
                      <td className="interest align-middle white-space-nowrap">
                        110
                      </td>
                      <td>
                        <form
                          method="POST"
                          id="regForm"
                          action="confirmpurchase"
                        >
                          <input
                            type="hidden"
                            name="_token"
                            defaultValue="Zah9a2H5c9Lb6RQiFz5hiTc0UNPPJv2cGNmZHXqv"
                            autoComplete="off"
                          />
                          <input
                            type="hidden"
                            defaultValue={110}
                            name="amount"
                          />
                          <input
                            type="hidden"
                            defaultValue={4}
                            name="packageid"
                          />
                          <input
                            type="hidden"
                            defaultValue="Basic Plan"
                            name="packagetitle"
                          />
                          <input
                            type="hidden"
                            defaultValue={200}
                            name="totaltime"
                          />
                          <i
                            className="fa fa-shopping-cart"
                            style={{ fontSize: 22 }}
                          />
                        </form>
                      </td>
                    </tr>
                    <tr className="hover-actions-trigger btn-reveal-trigger position-static">
                      <td className="sr align-middle white-space-nowrap">4</td>
                      <td className="customer align-middle white-space-nowrap">
                        Free Trail
                      </td>
                      <td className="interest align-middle white-space-nowrap">
                        00:01:40
                      </td>
                      <td className="interest align-middle white-space-nowrap">
                        0
                      </td>
                      <td>
                        <form
                          method="POST"
                          id="regForm"
                          action="confirmpurchase"
                        >
                          <input
                            type="hidden"
                            name="_token"
                            defaultValue="Zah9a2H5c9Lb6RQiFz5hiTc0UNPPJv2cGNmZHXqv"
                            autoComplete="off"
                          />
                          <input type="hidden" defaultValue={0} name="amount" />
                          <input
                            type="hidden"
                            defaultValue={5}
                            name="packageid"
                          />
                          <input
                            type="hidden"
                            defaultValue="Free Trail"
                            name="packagetitle"
                          />
                          <input
                            type="hidden"
                            defaultValue={100}
                            name="totaltime"
                          />
                          <i
                            className="fa fa-shopping-cart"
                            style={{ fontSize: 22 }}
                          />
                        </form>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <footer className="footer position-absolute">
            <div className="row g-0 justify-content-between align-items-center h-100">
              <div className="col-12 col-sm-auto text-center">
                <p className="mb-0 mt-2 mt-sm-0 text-900">
                  All rights reserved
                  <span className="d-none d-sm-inline-block" />
                  <span className="d-none d-sm-inline-block mx-1">|</span>
                  <br className="d-sm-none" />
                  2024 ©
                  {/* <a className="mx-1" href="#">
                    Medical App
                  </a> */}
                </p>
              </div>
            </div>
          </footer>
        </div>
      </main>
    </>
  );
}
