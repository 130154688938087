import React from 'react';

export default function Social() {
  return (
    <>
      <section className="py-5 pt-xl-13 bg-body-emphasis">
        <div className="container-small px-lg-7 px-xxl-3">
          <div className="row g-0">
            <div className="col-6 col-md-3">
              <div className="p-2 p-lg-5 d-flex flex-center h-100 border-1 border-dashed border-bottom border-translucent border-end">
                <img
                  className="w-100"
                  src="assets/img/brand2/netflix-n.png"
                  alt=""
                />
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="p-2 p-lg-5 d-flex flex-center h-100 border-1 border-dashed border-bottom border-translucent border-end-md">
                <img
                  className="w-100"
                  src="assets/img/brand2/blender.png"
                  alt=""
                />
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="p-2 p-lg-5 d-flex flex-center h-100 border-1 border-dashed border-bottom border-translucent border-end border-end-md">
                <img
                  className="w-100"
                  src="assets/img/brand2/upwork.png"
                  alt=""
                />
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="p-2 p-lg-5 d-flex flex-center h-100 border-1 border-dashed border-bottom border-translucent border-end-lg-0">
                <img
                  className="w-100"
                  src="assets/img/brand2/facebook-f.png"
                  alt=""
                />
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="p-2 p-lg-5 d-flex flex-center h-100 border-1 border-dashed border-end border-bottom border-translucent border-bottom-md-0">
                <img
                  className="w-100"
                  src="assets/img/brand2/pocket.png"
                  alt=""
                />
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="p-2 p-lg-5 d-flex flex-center h-100 border-1 border-dashed border-end-md border-bottom border-translucent border-bottom-md-0">
                <img
                  className="w-100"
                  src="assets/img/brand2/mail-bluster-1.png"
                  alt=""
                />
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="p-2 p-lg-5 d-flex flex-center h-100 border-1 border-dashed border-end border-translucent">
                <img
                  className="w-100"
                  src="assets/img/brand2/discord.png"
                  alt=""
                />
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="p-2 p-lg-5 d-flex flex-center h-100 border-1 border-dashed border-end-lg-0 border-translucent">
                <img
                  className="w-100"
                  src="assets/img/brand2/google-g.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
