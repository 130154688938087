import React from 'react';

export default function Home() {
  return (
    <>
      <main className="main" id="top">
        <div
          className="bg-body-emphasis sticky-top"
          data-navbar-shadow-on-scroll="data-navbar-shadow-on-scroll"
        >
          <nav className="navbar navbar-expand-lg container-small px-2 px-lg-7 px-xxl-2">
            <a className="navbar-brand flex-1 flex-lg-grow-0">
              <div className="d-flex align-items-center">
                <h5 className="logo-text fa-9 fw-bold ms-2">Medical App</h5>
              </div>
            </a>
            <div className="d-lg-none"></div>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <div className="border-bottom border-translucent border-bottom-lg-0 mb-2">
                <div className="search-box d-inline d-lg-none">
                  <form className="position-relative">
                    <input
                      className="form-control search-input search rounded-pill my-4"
                      type="search"
                      placeholder="Search"
                      aria-label="Search"
                    />
                    <span className="fas fa-search search-box-icon"></span>
                  </form>
                </div>
              </div>
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item border-bottom border-translucent border-bottom-lg-0">
                  <a
                    className="nav-link lh-1 py-0 fs-9 fa-9 py-3"
                    aria-current="page"
                    href="/"
                  >
                    Home
                  </a>
                </li>
                <li className="nav-item border-bottom border-translucent border-bottom-lg-0">
                  <a
                    className="nav-link lh-1 py-0 fs-9 fa-9 py-3"
                    href="#feature"
                  >
                    Features
                  </a>
                </li>
                <li className="nav-item border-bottom border-translucent border-bottom-lg-0">
                  <a className="nav-link lh-1 py-0 fs-9 fa-9 py-3" href="#blog">
                    Blog
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link lh-1 py-0 fs-9 fa-9 py-3" href="#team">
                    Team
                  </a>
                </li>
              </ul>
              <div className="d-grid d-lg-flex align-items-center">
                <a
                  className="text-body-quaternary text-body-tertiary-hover px-2 d-none d-lg-inline lh-sm"
                  href="/"
                  data-bs-toggle="modal"
                  data-bs-target="#searchBoxModal"
                >
                  <span
                    data-feather="search"
                    style={{ height: "20px", width: "20px" }}
                  ></span>
                </a>
                <a
                  className="btn btn-link text-body fa-9 order-1 order-lg-0 ps-4 me-lg-2"
                  href="/login"
                >
                  Sign in
                </a>
                <a
                  className="btn btn-phoenix-primary fa-9 order-0"
                  href="/register"
                >
                  <span className="fa-9">Sign up</span>
                </a>
              </div>
            </div>
          </nav>
        </div>

        <div
          className="modal fade"
          id="searchBoxModal"
          tabindex="-1"
          aria-hidden="true"
          data-bs-backdrop="true"
          data-phoenix-modal="data-phoenix-modal"
          style={{ "--phoenix-backdrop-opacity": 1 }}
        >
          <div className="modal-dialog">
            <div className="modal-content mt-15 rounded-pill">
              <div className="modal-body p-0">
                <div
                  className="search-box navbar-top-search-box"
                  data-list='{"valueNames":["title"]}'
                  style={{ width: "auto" }}
                >
                  <form
                    className="position-relative"
                    data-bs-toggle="search"
                    data-bs-display="static"
                  >
                    <input
                      className="form-control search-input fuzzy-search rounded-pill form-control-lg"
                      type="search"
                      placeholder="Search..."
                      aria-label="Search"
                    />
                    <span className="fas fa-search search-box-icon"></span>
                  </form>
                  <div
                    className="btn-close position-absolute end-0 top-50 translate-middle cursor-pointer shadow-none"
                    data-bs-dismiss="search"
                  >
                    <button
                      className="btn btn-link p-0"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="dropdown-menu border start-0 py-0 overflow-hidden w-100">
                    <div
                      className="scrollbar-overlay"
                      style={{ maxHeight: "30rem" }}
                    >
                      <div className="list pb-3">
                        <h6 className="dropdown-header text-body-highlight fs-10 py-2">
                          24{" "}
                          <span className="text-body-quaternary">results</span>
                        </h6>
                        <hr className="my-0" />
                        <h6 className="dropdown-header text-body-highlight fs-9 border-bottom border-translucent py-2 lh-sm">
                          Recently Searched{" "}
                        </h6>
                        <div className="py-2">
                          <a
                            className="dropdown-item"
                            href="apps/e-commerce/landing/product-details.html"
                          >
                            <div className="d-flex align-items-center">
                              <div className="fw-normal text-body-highlight title">
                                <span
                                  className="fa-solid fa-clock-rotate-left"
                                  data-fa-transform="shrink-2"
                                ></span>{" "}
                                Store Macbook
                              </div>
                            </div>
                          </a>
                          <a
                            className="dropdown-item"
                            href="apps/e-commerce/landing/product-details.html"
                          >
                            <div className="d-flex align-items-center">
                              <div className="fw-normal text-body-highlight title">
                                {" "}
                                <span
                                  className="fa-solid fa-clock-rotate-left"
                                  data-fa-transform="shrink-2"
                                ></span>{" "}
                                MacBook Air - 13″
                              </div>
                            </div>
                          </a>
                        </div>
                        <hr className="my-0" />
                        <h6 className="dropdown-header text-body-highlight fs-9 border-bottom border-translucent py-2 lh-sm">
                          Products
                        </h6>
                        <div className="py-2">
                          <a
                            className="dropdown-item py-2 d-flex align-items-center"
                            href="apps/e-commerce/landing/product-details.html"
                          >
                            <div className="file-thumbnail me-2">
                              <img
                                className="h-100 w-100 object-fit-cover rounded-3"
                                src="assets/img/products/60x60/3.png"
                                alt=""
                              />
                            </div>
                            <div className="flex-1">
                              <h6 className="mb-0 text-body-highlight title">
                                MacBook Air - 13″
                              </h6>
                              <p className="fs-10 mb-0 d-flex text-body-tertiary">
                                <span className="fw-medium text-body-tertiary text-opactity-85">
                                  8GB Memory - 1.6GHz - 128GB Storage
                                </span>
                              </p>
                            </div>
                          </a>
                          <a
                            className="dropdown-item py-2 d-flex align-items-center"
                            href="apps/e-commerce/landing/product-details.html"
                          >
                            <div className="file-thumbnail me-2">
                              <img
                                className="img-fluid"
                                src="assets/img/products/60x60/3.png"
                                alt=""
                              />
                            </div>
                            <div className="flex-1">
                              <h6 className="mb-0 text-body-highlight title">
                                MacBook Pro - 13″
                              </h6>
                              <p className="fs-10 mb-0 d-flex text-body-tertiary">
                                <span className="fw-medium text-body-tertiary text-opactity-85">
                                  30 Sep at 12:30 PM
                                </span>
                              </p>
                            </div>
                          </a>
                        </div>
                        <hr className="my-0" />
                        <h6 className="dropdown-header text-body-highlight fs-9 border-bottom border-translucent py-2 lh-sm">
                          Quick Links
                        </h6>
                        <div className="py-2">
                          <a
                            className="dropdown-item"
                            href="apps/e-commerce/landing/product-details.html"
                          >
                            <div className="d-flex align-items-center">
                              <div className="fw-normal text-body-highlight title">
                                <span
                                  className="fa-solid fa-link text-body"
                                  data-fa-transform="shrink-2"
                                ></span>{" "}
                                Support MacBook House
                              </div>
                            </div>
                          </a>
                          <a
                            className="dropdown-item"
                            href="apps/e-commerce/landing/product-details.html"
                          >
                            <div className="d-flex align-items-center">
                              <div className="fw-normal text-body-highlight title">
                                {" "}
                                <span
                                  className="fa-solid fa-link text-body"
                                  data-fa-transform="shrink-2"
                                ></span>{" "}
                                Store MacBook″
                              </div>
                            </div>
                          </a>
                        </div>
                        <hr className="my-0" />
                        <h6 className="dropdown-header text-body-highlight fs-9 border-bottom border-translucent py-2 lh-sm">
                          Files
                        </h6>
                        <div className="py-2">
                          <a
                            className="dropdown-item"
                            href="apps/e-commerce/landing/product-details.html"
                          >
                            <div className="d-flex align-items-center">
                              <div className="fw-normal text-body-highlight title">
                                <span
                                  className="fa-solid fa-file-zipper text-body"
                                  data-fa-transform="shrink-2"
                                ></span>{" "}
                                Library MacBook folder.rar
                              </div>
                            </div>
                          </a>
                          <a
                            className="dropdown-item"
                            href="apps/e-commerce/landing/product-details.html"
                          >
                            <div className="d-flex align-items-center">
                              <div className="fw-normal text-body-highlight title">
                                {" "}
                                <span
                                  className="fa-solid fa-file-lines text-body"
                                  data-fa-transform="shrink-2"
                                ></span>{" "}
                                Feature MacBook extensions.txt
                              </div>
                            </div>
                          </a>
                          <a
                            className="dropdown-item"
                            href="apps/e-commerce/landing/product-details.html"
                          >
                            <div className="d-flex align-items-center">
                              <div className="fw-normal text-body-highlight title">
                                {" "}
                                <span
                                  className="fa-solid fa-image text-body"
                                  data-fa-transform="shrink-2"
                                ></span>{" "}
                                MacBook Pro_13.jpg
                              </div>
                            </div>
                          </a>
                        </div>
                        <hr className="my-0" />
                        <h6 className="dropdown-header text-body-highlight fs-9 border-bottom border-translucent py-2 lh-sm">
                          Members
                        </h6>
                        <div className="py-2">
                          <a
                            className="dropdown-item py-2 d-flex align-items-center"
                            href="pages/members.html"
                          >
                            <div className="avatar avatar-l status-online  me-2 text-body">
                              <img
                                className="rounded-circle "
                                src="assets/img/team/40x40/10.webp"
                                alt=""
                              />
                            </div>
                            <div className="flex-1">
                              <h6 className="mb-0 text-body-highlight title">
                                Carry Anna
                              </h6>
                              <p className="fs-10 mb-0 d-flex text-body-tertiary">
                                anna@technext.it
                              </p>
                            </div>
                          </a>
                          <a
                            className="dropdown-item py-2 d-flex align-items-center"
                            href="pages/members.html"
                          >
                            <div className="avatar avatar-l  me-2 text-body">
                              <img
                                className="rounded-circle "
                                src="assets/img/team/40x40/12.webp"
                                alt=""
                              />
                            </div>
                            <div className="flex-1">
                              <h6 className="mb-0 text-body-highlight title">
                                John Smith
                              </h6>
                              <p className="fs-10 mb-0 d-flex text-body-tertiary">
                                smith@technext.it
                              </p>
                            </div>
                          </a>
                        </div>
                        <hr className="my-0" />
                        <h6 className="dropdown-header text-body-highlight fs-9 border-bottom border-translucent py-2 lh-sm">
                          Related Searches
                        </h6>
                        <div className="py-2">
                          <a
                            className="dropdown-item"
                            href="apps/e-commerce/landing/product-details.html"
                          >
                            <div className="d-flex align-items-center">
                              <div className="fw-normal text-body-highlight title">
                                <span
                                  className="fa-brands fa-firefox-browser text-body"
                                  data-fa-transform="shrink-2"
                                ></span>{" "}
                                Search in the Web MacBook
                              </div>
                            </div>
                          </a>
                          <a
                            className="dropdown-item"
                            href="apps/e-commerce/landing/product-details.html"
                          >
                            <div className="d-flex align-items-center">
                              <div className="fw-normal text-body-highlight title">
                                {" "}
                                <span
                                  className="fa-brands fa-chrome text-body"
                                  data-fa-transform="shrink-2"
                                ></span>{" "}
                                Store MacBook″
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                      <div className="text-center">
                        <p className="fallback fw-bold fs-7 d-none">
                          No Result Found.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="bg-body-emphasis pb-8" id="home">
          <div className="container-small hero-header-container px-lg-7 px-xxl-3">
            <div className="row align-items-center">
              <div className="col-12 col-lg-auto order-0 order-md-1 text-end order-1">
                <div className="position-relative p-5 p-md-7 d-lg-none">
                  <div
                    className="bg-holder"
                    style={{
                      backgroundImage: "url(assets/img/bg/bg-23.png)",
                      backgroundSize: "contain",
                    }}
                  ></div>

                  <div className="position-relative">
                    <img
                      className="w-100 shadow-lg d-dark-none rounded-2"
                      src="assets/img/bg/bg-31.png"
                      alt="hero-header"
                    />
                    <img
                      className="w-100 shadow-lg d-light-none rounded-2"
                      src="assets/img/bg/bg-30.png"
                      alt="hero-header"
                    />
                  </div>
                </div>
                <div className="hero-image-container position-absolute top-0 bottom-0 end-0 d-none d-lg-block">
                  <div className="position-relative h-100 w-100">
                    <div className="position-absolute h-100 top-0 d-flex align-items-center end-0 hero-image-container-bg">
                      <img
                        className="pt-7 pt-md-0 w-100"
                        src="assets/img/bg/bg-1-2.png"
                        alt="hero-header"
                      />
                    </div>
                    <div className="position-absolute h-100 top-0 d-flex align-items-center end-0">
                      <img
                        className="pt-7 pt-md-0 w-100 shadow-lg d-dark-none rounded-2"
                        src="assets/img/bg/bg-28.png"
                        alt="hero-header"
                      />
                      <img
                        className="pt-7 pt-md-0 w-100 shadow-lg d-light-none rounded-2"
                        src="assets/img/bg/bg-29.png"
                        alt="hero-header"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6 text-lg-start text-center pt-8 pb-6 order-0 position-relative">
                <h1 className="fa-3 fs-lg-2 fs-md-1 fs-lg-2  fs fw-black mb-4">
                  <span className="text-primary me-3">Elegance</span>for
                  <br />
                  your web app
                </h1>
                <p className="fa-9 mb-5">
                  Standard, modern and Elegant solution for your next web app so
                  you don’t have to look further. Sign up or check the demo
                  below.
                </p>
                <a
                  className="btn btn-lg btn-primary fa-9 rounded-pill me-3"
                  role="button"
                >
                  Sign up
                </a>
                <a
                  className="btn btn-link me-2 fs-8 fa-9 p-0"

                  role="button"
                >
                  Check Demo
                  <span className="fa-solid fa-angle-right ms-2 fs-9"></span>
                </a>
              </div>
            </div>
          </div>
        </section>


      </main>
    </>
  );
}
