import React from 'react';

export default function Testimonial(){
    return(
        <>
              <div className="position-relative">
          <div
            className="bg-holder z-2 d-none d-md-block"
            style={{
              backgroundImage: "url(assets/img/bg/13.png)",
              backgroundSize: "auto",
              backgroundPosition: "right",
            }}
          ></div>

          <div
            className="bg-holder z-2 d-none d-md-block d-lg-none d-xl-block"
            style={{
              backgroundImage: "url(assets/img/bg/bg-12.png)",
              backgroundSize: "auto",
              backgroundPosition: "left",
            }}
          ></div>

          <div className="bottom-0 start-0 end-0 bg-body-emphasis">
            <svg
              className="w-100"
              viewBox="0 0 1920 368"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                className="fill-body-bg"
                d="M1920 0.44L0 367.74V0H1920V0.44Z"
              ></path>
            </svg>
          </div>
          <section className="pb-0 bg-body-emphasis overflow-hidden position-static">
            <div className="container-small px-lg-7 px-xxl-3">
              <div className="row">
                <div className="col-lg-6 mb-6 text-center text-lg-start z-2">
                  <h4 className="text-primary fa-9 mb-3">Testimonial</h4>
                  <h2
                    className="mb-3 text-body-emphasis fa-9"
                    style={{ fontSize: "26px" }}
                  >
                    More than 2 Millions happy
                    <br />
                    Customers and counting
                  </h2>
                  <p className="fa-9 mb-5">
                    You may now concentrate on the functionality and other{" "}
                    <br className="d-none d-sm-block" />
                    aspects of your web products thanks to Phoenix's strength
                    <br className="d-none d-sm-block" />
                    before leaving the UI design to us. It is simple to complete
                    <br className="d-none d-sm-block" />
                    the work after checking and double-checking.
                  </p>
                </div>
                <div className="col-lg-6 z-2">
                  <div
                    className="carousel slide"
                    id="carouselExampleIndicators"
                    data-bs-ride="carousel"
                  >
                    <div className="carousel-inner">
                      <div className="carousel-item active">
                        <div className="row g-1 g-lg-0 g-xl-1 pb-lg-3 pb-xl-0 ps-lg-1 ps-xl-0">
                          <div className="col-lg-6 col-xl-5 text-center">
                            <div className="testimonial-avatar-container d-inline-block position-relative">
                              <div
                                className="bg-holder"
                                style={{
                                  backgroundImage:
                                    "url(assets/img/bg/bg-2.png)",
                                  backgroundSize: "contain",
                                }}
                              ></div>
                              <img
                                className="rounded-3 mb-lg-0 opacity-100 position-relative"
                                src="assets/img/team/61.webp"
                                width="153"
                                height="153"
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 col-xl-7 text-center text-lg-start">
                            <div className="mb-4" data-bs-theme="light">
                              <span className="fa fa-star text-primary"></span>
                              <span className="fa fa-star text-primary"></span>
                              <span className="fa fa-star text-primary"></span>
                              <span className="fa fa-star text-primary"></span>
                              <span className="fa fa-star text-primary"></span>
                            </div>
                            <h3 className="fs-7 fa-9 mb-5 lh-sm me-md-7 me-lg-0">
                              Brilliant app! Will definitely going to be my
                              first choice starting from today
                            </h3>
                            <h6 className="fa-9">Monica Gomez</h6>
                            <h6 className="fa-9 fw-normal">
                              UX designer, Google
                            </h6>
                          </div>
                        </div>
                      </div>
                      <div className="carousel-item">
                        <div className="row g-1 g-lg-0 g-xl-1 pb-lg-3 pb-xl-0 ps-lg-1 ps-xl-0">
                          <div className="col-lg-6 col-xl-5 text-center">
                            <div className="testimonial-avatar-container d-inline-block position-relative">
                              <div
                                className="bg-holder"
                                style={{
                                  backgroundImage:
                                    "url(assets/img/bg/bg-2.png)",
                                  backgroundSize: "contain",
                                }}
                              ></div>
                              <img
                                className="rounded-3 mb-lg-0 opacity-100 position-relative"
                                src="assets/img/team/8.webp"
                                width="154"
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 col-xl-7 text-center text-lg-start">
                            <div className="mb-4" data-bs-theme="light">
                              <span className="fa fa-star text-primary"></span>
                              <span className="fa fa-star text-primary"></span>
                              <span className="fa fa-star text-primary"></span>
                              <span className="fa fa-star text-primary"></span>
                              <span className="fa fa-star text-primary"></span>
                            </div>
                            <h3 className="fs-7 fa-9 mb-5 lh-sm me-md-7 me-lg-0">
                              “Excellent to work with and comfortable to
                              customize. This is what I was looking for till the
                              date!”
                            </h3>
                            <h6>Marc Chiasson</h6>
                            <h6 className="fw-normal">UX designer, Adobe</h6>
                          </div>
                        </div>
                      </div>
                      <div className="carousel-item">
                        <div className="row g-1 g-lg-0 g-xl-1 pb-lg-3 pb-xl-0 ps-lg-1 ps-xl-0">
                          <div className="col-lg-6 col-xl-5 text-center">
                            <div className="testimonial-avatar-container d-inline-block position-relative">
                              <div
                                className="bg-holder"
                                style={{
                                  backgroundImage:
                                    "url(assets/img/bg/bg-2.png)",
                                  backgroundSize: "contain",
                                }}
                              ></div>
                              <img
                                className="rounded-3 mb-lg-0 opacity-100 position-relative"
                                src="assets/img/team/35.webp"
                                width="154"
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 col-xl-7 text-center text-lg-start">
                            <div className="mb-4" data-bs-theme="light">
                              <span className="fa fa-star text-primary"></span>
                              <span className="fa fa-star text-primary"></span>
                              <span className="fa fa-star text-primary"></span>
                              <span className="fa fa-star text-primary"></span>
                              <span className="fa fa-star text-primary"></span>
                            </div>
                            <h3 className="fs-7 fa-9 mb-5 lh-sm me-md-7 me-lg-0">
                              “Phoenix is all you can ask for. This is perfect
                              fit for everything you might want to work on!”
                            </h3>
                            <h6>Axel Barry</h6>
                            <h6 className="fw-normal">UX designer, Apple</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        <div className="position-relative">
          <div
            className="bg-holder world-map-bg"
            style={{
              backgroundImage: "url(assets/img/bg/bg-13.png)",
            }}
          ></div>

          <div
            className="bg-holder z-2 opacity-25 "
            style={{
              backgroundImage: "url(assets/img/bg/bg-right-21.png)",
              backgroundSize: "auto",
              backgroundPosition: "right",
            }}
          ></div>

          <div
            className="bg-holder z-2 mt-9 opacity-25"
            style={{
              backgroundImage: "url(assets/img/bg/bg-left-21.png)",
              backgroundSize: "auto",
              backgroundPosition: "left",
            }}
          ></div>

          <section className="overflow-hidden z-2">
            <div
              className="container-small px-lg-7 px-xxl-3"
              data-bs-theme="light"
            >
              <div className="position-relative">
                <div className="row mb-6">
                  <div className="col-xl-6 text-center text-md-start">
                    <h2
                      className="text-white fa-9 mb-2"
                      style={{ fontSize: "28px" }}
                    >
                      Being used by millions of users
                    </h2>
                    <h1 className="fs-md-3 fs-xl-2 fw-black text-gradient-info fa-9 text-uppercase mb-4 mb-md-0">
                      WORLDWIDE
                    </h1>
                  </div>
                  <div className="col-xl-6 text-center text-md-start">
                    <p className="fa-9 text-white">
                      You can get all the reports, data analysis, and growth
                      maps you need with the help of Phoenix's power, and you
                      may review and modify them whenever you want. These
                      features make this dashboard outstanding.
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-8 text-center text-md-start mb-6 mb-xl-0">
                    <div className="d-md-flex justify-content-md-between">
                      <div className="mb-6 mb-md-0 me-4">
                        <h1
                          className="display-1 text-white fw-bolder"
                          data-countup='{"endValue":125,"duration":10,"suffix":"+"}'
                        >
                          0 <span>+</span>
                        </h1>
                        <p className="fa-9 text-white">
                          Every month, there are more
                          <br className="d-md-none d-lg-block" />
                          than 125+ sales.
                        </p>
                      </div>
                      <div className="mb-6 mb-md-0 me-4">
                        <h1
                          className="display-1 text-white fw-bolder"
                          data-countup='{"endValue":308,"duration":10,"suffix":"k"}'
                        >
                          0
                        </h1>
                        <p className="fa-9 text-white">
                          We have 308+ active paid.
                          <br className="d-md-none d-lg-block" />
                          subscribers.
                        </p>
                      </div>
                      <div className="mb-6 mb-md-0 me-4">
                        <h1
                          className="display-1 text-white fw-bolder"
                          data-countup='{"endValue":12,"duration":0.5}'
                        >
                          0
                        </h1>
                        <p className="fa-9 text-white">
                          We have won 12 awards so
                          <br className="d-md-none d-lg-block" />
                          far with great success.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 text-center text-md-start">
                    <img
                      className="img-fluid"
                      src="assets/img/generic/capterra.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <svg
            className="w-100 position-relative"
            viewBox="0 0 1920 368"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              className="fill-emphasis-bg"
              d="M0 368L1920 0.730011L1920 368L0 368Z"
            ></path>
          </svg>
        </div>
        </>
    )
}