import React from 'react';

export default function Footer(){
    return(
        <>
              <div className="position-relative">
          <div
            className="bg-holder"
            style={{
              backgroundImage: "url(assets/img/bg/bg-right-20.png)",
              backgroundPosition: "right",
              backgroundSize: "auto",
            }}
          ></div>
          <div
            className="bg-holder"
            style={{
              backgroundImage: "url(assets/img/bg/bg-right-20.png)",
              backgroundPosition: "left",
              backgroundSize: "auto",
            }}
          ></div>
          <div className="position-relative">
            <svg
              className="w-100 text-white dark__text-gray-1100"
              preserveAspectRatio="none"
              viewBox="0 0 1920 368"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1920 0.44L0 367.74V0H1920V0.44Z"
                fill="currentColor"
              ></path>
            </svg>
            <section
              className="footer-default"
              style={{
                backgroundColor: "rgba(245, 245, 247, .8)",
                important: true,
              }}
            >
              <div className="container-small px-lg-7 px-xxl-3">
                <div className="row position-relative">
                  <div className="col-12 col-sm-12 col-lg-5 mb-4 order-0 order-sm-0">
                    <a>
                      <img
                        className="mb-3"
                        src="assets/img/icons/logo.png"
                        height="48"
                        alt=""
                      />
                    </a>
                    <h3 className="fa-9" style={{ fontSize: "20px" }}>
                      phoenix
                    </h3>
                    <p className="text-black fa-9 opacity-50">
                      All over the world. Alice in <br />
                      wonderland and other places.
                    </p>
                  </div>
                  <div className="col-lg-7">
                    <div className="row justify-content-between">
                      <div className="col-6 col-sm-4 col-lg-3 mb-3 order-2 order-sm-1">
                        <div
                          className="border-dashed border-start border-primary-light ps-3"
                          style={{
                            "--phoenix-border-opacity": ".2",
                          }}
                        >
                          <h5 className="mb-2 text-black fa-9">Help</h5>
                          <ul className="list-unstyled mb-3">
                            <li className="mb-1">
                              <a
                                className="text-body-quaternary fa-9"
                                data-bs-theme="light"
                              >
                                About
                              </a>
                            </li>
                            <li className="mb-1">
                              <a
                                className="text-body-quaternary fa-9"
                                data-bs-theme="light"
                              >
                                Contact
                              </a>
                            </li>
                            <li className="mb-1">
                              <a
                                className="text-body-quaternary fa-9"
                                data-bs-theme="light"
                              >
                                Developers
                              </a>
                            </li>
                          </ul>
                        </div>
                        <div
                          className="border-dashed border-start border-primary-light ps-3"
                          style={{
                            "--phoenix-border-opacity": ".2",
                          }}
                        >
                          <h5 className="lh-lg mb-2 text-black fa-9">Follow</h5>
                          <ul className="list-unstyled mb-2">
                            <li className="mb-1">
                              <a
                                className="text-body-quaternary fa-9"
                                data-bs-theme="light"
                              >
                                Facebook
                              </a>
                            </li>
                            <li className="mb-1">
                              <a
                                className="text-body-quaternary fa-9"
                                data-bs-theme="light"
                              >
                                Twitter
                              </a>
                            </li>
                            <li className="mb-1">
                              <a
                                className="text-body-quaternary fa-9"
                                data-bs-theme="light"
                              >
                                Linkedin
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-6 col-sm-4 col-lg-3 mb-3 order-3 order-sm-2">
                        <div
                          className="border-dashed border-start border-primary-light ps-3"
                          style={{
                            "--phoenix-border-opacity": ".2",
                          }}
                        >
                          <h5 className="lh-lg  text-black mb-2 fa-9">
                            Support
                          </h5>
                          <ul className="list-unstyled mb-md-2">
                            <li className="mb-1">
                              <a
                                className="text-body-quaternary fa-9"
                                data-bs-theme="light"
                              >
                                Privacy
                              </a>
                            </li>
                            <li className="mb-1">
                              <a
                                className="text-body-quaternary fa-9"
                                data-bs-theme="light"
                              >
                                Community
                              </a>
                            </li>
                            <li className="mb-1">
                              <a
                                className="text-body-quaternary fa-9"
                                data-bs-theme="light"
                              >
                                Contact
                              </a>
                            </li>
                            <li className="mb-1">
                              <a
                                className="text-body-quaternary fa-9"
                                data-bs-theme="light"
                              >
                                Blog
                              </a>
                            </li>
                            <li className="mb-1">
                              <a
                                className="text-body-quaternary fa-9"
                                data-bs-theme="light"
                              >
                                FAQ
                              </a>
                            </li>
                            <li className="mb-1">
                              <a
                                className="text-body-quaternary fa-9"
                                data-bs-theme="light"
                              >
                                Project
                              </a>
                            </li>
                            <li className="mb-1">
                              <a
                                className="text-body-quaternary fa-9"
                                data-bs-theme="light"
                              >
                                Team
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-6 col-sm-4 col-lg-3 mb-3 order-3 order-sm-2">
                        <div
                          className="border-dashed border-start border-primary-light ps-3"
                          style={{
                            "--phoenix-border-opacity": ".2",
                          }}
                        >
                          <h5 className="lh-lg text-black mb-2 fa-9"> Info</h5>
                          <ul className="list-unstyled mb-md-2">
                            <li className="mb-1">
                              <a
                                className="text-body-quaternary fa-9"
                                data-bs-theme="light"
                              >
                                Personal
                              </a>
                            </li>
                            <li className="mb-1">
                              <a
                                className="text-body-quaternary fa-9"
                                data-bs-theme="light"
                              >
                                NFT System
                              </a>
                            </li>
                            <li className="mb-1">
                              <a
                                className="text-body-quaternary fa-9"
                                data-bs-theme="light"
                              >
                                Agency
                              </a>
                            </li>
                            <li className="mb-1">
                              <a
                                className="text-body-quaternary fa-9"
                                data-bs-theme="light"
                              >
                                Contact
                              </a>
                            </li>
                            <li className="mb-1">
                              <a
                                className="text-body-quaternary fa-9"
                                data-bs-theme="light"
                              >
                                About
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section
              className="dark__bg-gray-1000"
              data-bs-theme="dark"
              style={{
                backgroundColor: "rgba(245, 245, 247, .8)",
                height: "125px",
              }}
            >
              <div className="container-small px-lg-7 px-xxl-3">
                <hr style={{ color: "#cccfdb" }} />

                <div className="d-sm-flex flex-between-center text-center">
                  <p className="text-body-tertiary fa-9 mb-0">
                    Copyright © Company Name
                  </p>
                  <p className="text-body-tertiary fa-9 mb-0">
                    Made with love by{" "}
                    <a >ThemeWagon</a>
                  </p>
                </div>
              </div>
            </section>
          </div>
        </div>
        </>
    )
}