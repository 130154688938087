import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Loader from "react-js-loader";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setErrorMessage("");

    if (!email || !password) {
      setErrorMessage("Both email and password are required");
      return;
    }

    const loginData = {
      email: email,
      password: password,
    };

    try {
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/login`,
        loginData
      );
      if (response.status === 200) {
        setLoading(false);
        console.log(response);

        let loggedInUser = {
          userId:response.data.user_id,
          token :response.data.token,
          name:response.data.user_name
        }

        localStorage.setItem("loggedInUser", JSON.stringify(loggedInUser));
        navigate("/admin/dashboard");
      } else {
        setLoading(false);
        setErrorMessage(response.data.message);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error during login", error);
      setErrorMessage("An error occurred during login. Please try again.");
    }
  };

  return (
    <>
      <main className="main" id="top">
        <div className="register-main-container">
          <div className="row flex-center min-vh-100">
            <div className="col-sm-10 col-md-8 col-lg-5 col-xl-5 col-xxl-3">
              <Link className="d-flex flex-center text-decoration-none mb-4">
                <div className="d-flex align-items-center fw-bolder fs-3 d-inline-block">
                  {/* <img
                    src="img/cover20240825061625.png"
                    alt="Medical App"
                    width={58}
                  /> */}
                </div>
              </Link>

              {loading ? (
                <>
                  <Loader
                    type="spinner-default"
                    loading={loading}
                    bgColor={"black"}
                    color={"black"}
                    title={"loading..."}
                    size={50}
                  />
                </>
              ) : (
                <>
                  <div className="text-center mb-5">
                    <h3 className="text-body-highlight">Sign In</h3>
                    <p className="text-body-tertiary">
                      Get access to your account
                    </p>
                  </div>
                  <Link className="btn btn-phoenix-secondary w-100 mb-3">
                    <span className="fab fa-google text-danger me-2 fs-9" />
                    Sign in with google
                  </Link>
                  <form onSubmit={handleSubmit}>
                    <div className="position-relative">
                      <hr className="bg-body-secondary mt-4 mb-3" />
                      <div className="divider-content-center">or use email</div>
                    </div>
                    {errorMessage && (
                      <div className="alert alert-danger" role="alert">
                        {errorMessage}
                      </div>
                    )}

                    <div className="mb-3 text-start">
                      <label className="form-label" htmlFor="email">
                        Email address
                      </label>
                      <div className="form-icon-container">
                        <input
                          className="form-control form-icon-input"
                          id="email"
                          type="email"
                          name="email"
                          value={email}
                          onChange={handleEmailChange}
                          placeholder="name@example.com"
                          required
                        />
                        <span className="fas fa-user text-body fs-9 form-icon" />
                      </div>
                    </div>
                    <div className="mb-3 text-start">
                      <label className="form-label" htmlFor="password">
                        Password
                      </label>
                      <div
                        className="form-icon-container"
                        data-password="data-password"
                      >
                        <input
                          className="form-control form-icon-input pe-6"
                          id="password"
                          type="password"
                          placeholder="Password"
                          name="password"
                          value={password}
                          onChange={handlePasswordChange}
                          required=""
                        />
                        <span className="fas fa-key text-body fs-9 form-icon" />
                      </div>
                    </div>
                    <div className="row flex-between-center mb-5">
                      <div className="col-auto">
                        <div className="form-check mb-0">
                          <input
                            className="form-check-input"
                            id="basic-checkbox"
                            type="checkbox"
                            defaultChecked="checked"
                          />
                          <label
                            className="form-check-label mb-0"
                            htmlFor="basic-checkbox"
                          >
                            Remember me
                          </label>
                        </div>
                      </div>
                      <div className="col-auto">
                        <Link className="fs-9 fw-semibold">
                          Forgot Password?
                        </Link>
                      </div>
                    </div>
                    <button
                      type="submit"
                      className="btn btn-primary w-100 mb-3"
                    >
                      Sign In
                    </button>
                  </form>
                  <div className="text-center">
                    <Link className="fs-9 fw-bold">Create an account</Link>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
