import React from "react";
import { useEffect, useState } from "react";
import pdfToText from "react-pdftotext";
import Loader from "react-js-loader";
import * as XLSX from "xlsx";
import mammoth from "mammoth";
import Papa from "papaparse";
import axios from "axios";

export default function ChatgptIntegration() {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [isListening, setIsListening] = useState(false);
  const [loading, setLoading] = useState(false);
  const [scrapinput, setScrapinput] = useState("");
  const [scrap, setScrap] = useState("");
  const [inputpy, setInputpy] = useState("");
  const [pyres, setPyres] = useState("");
  const [selectedTag, setSelectedTag] = useState("all");

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    console.log("fole", file);
    if (!file) return;

    let fileContent = "";

    switch (file.type) {
      case "application/pdf":
        fileContent = await extractTextFromPDF(file);
        break;

      case "text/plain":
        fileContent = await readTextFile(file);
        break;

      case "application/json":
        fileContent = await readJSONFile(file);
        break;
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      case "application/vnd.ms-excel":
        fileContent = await extractTextFromExcel(file);
        break;
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        fileContent = await extractTextFromDocx(file);
        break;
      case "text/csv":
        fileContent = await extractTextFromCSV(file);
        break;
      case "application/csv":
        fileContent = await extractTextFromCSV(file);
        break;
      default:
        fileContent = await extractDataFromUploadFile(file);
        return;
    }

    sendToChatGPT(fileContent);
  };

  const readTextFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
      reader.readAsText(file);
    });
  };

  const readJSONFile = async (file) => {
    const content = await readTextFile(file);
    try {
      const parsedData = JSON.parse(content);
      return JSON.stringify(parsedData);
    } catch (error) {
      console.error("Error parsing JSON file:", error);
      return "";
    }
  };

  const extractTextFromPDF = async (file) => {
    try {
      const content = await pdfToText(file);
      return content;
    } catch (err) {
      console.error("Error processing PDF file:", err);
      throw err;
    }
  };

  const extractTextFromDocx = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = async (e) => {
        try {
          const arrayBuffer = e.target.result;
          const result = await mammoth.extractRawText({ arrayBuffer });
          resolve(result.value); // The text content from the Word document
        } catch (err) {
          reject(err);
        }
      };
      reader.onerror = (error) => reject(error);
      reader.readAsArrayBuffer(file); // Read the file as an ArrayBuffer
    });
  };

  const extractTextFromExcel = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        console.log("data", data);
        const workbook = XLSX.read(data, { type: "binary" });

        // Get the first sheet name
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        console.log("sheet", sheet);
        // Convert sheet to JSON format
        const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 }); // Use header: 1 to extract row data as an array
        const textContent = jsonData.map((row) => row.join(" ")).join("\n"); // Join row data into text
        resolve(textContent);
      };
      reader.onerror = (error) => reject(error);
      reader.readAsBinaryString(file);
    });
  };

  const extractTextFromCSV = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        Papa.parse(event.target.result, {
          header: true, // Set to true to include headers
          skipEmptyLines: true,
          complete: (results) => {
            // Convert rows into a formatted string
            const formattedText = results.data
              .map((row) => Object.values(row).join(", "))
              .join("\n");
            resolve(formattedText);
          },
          error: (error) => {
            reject(error);
          },
        });
      };
      reader.onerror = (error) => reject(error);
      reader.readAsText(file);
    });
  };

  const extractDataFromUploadFile = async (file) => {};

  const handleSend = async () => {
    if (!input.trim()) return;

    const userMessage = { role: "user", content: input };
    setMessages((prevMessages) => [...prevMessages, userMessage]);

    try {
      setLoading(true);
      const response = await fetch(
        "https://api.openai.com/v1/chat/completions",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${process.env.REACT_APP_OPEN_AI_KEY}`,
          },
          body: JSON.stringify({
            model: "gpt-3.5-turbo",
            messages: [...messages, userMessage],
            // stream: true,
          }),
        }
      );
      setLoading(false);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      const botMessage = {
        role: "assistant",
        content: data.choices[0].message.content,
      };
      setMessages((prevMessages) => [...prevMessages, botMessage]);
    } catch (error) {
      setLoading(false);
      console.error("Error sending data to ChatGPT:", error);
    }

    setInput("");
  };

  const sendToChatGPT = async (text) => {
    try {
      setLoading(true);
      const response = await fetch(
        "https://api.openai.com/v1/chat/completions",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${process.env.REACT_APP_OPEN_AI_KEY}`,
          },
          body: JSON.stringify({
            model: "gpt-3.5-turbo",
            messages: [{ role: "user", content: text }],
          }),
        }
      );
      const data = await response.json();
      setLoading(false);
      setMessages((prevMessages) => [
        ...prevMessages,
        { role: "assistant", content: data.choices[0].message.content },
      ]);
    } catch (error) {
      console.error("Error sending data to ChatGPT:", error);
      throw error;
    }
  };

  const handleScrapping = async () => {
    // d0b0b9864f59405da41c51a78ea556a1b13cd379a8d
    if (!scrapinput.trim()) {
      alert("Please enter a valid URL");
      return;
    }

    const API_KEY = "d0b0b9864f59405da41c51a78ea556a1b13cd379a8d";
    const targetUrl = encodeURIComponent(scrapinput);

    try {
      setLoading(true);
      const response = await axios.get(
        `https://api.scrape.do?token=${API_KEY}&url=${targetUrl}`
      );
      setScrap(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error scraping the website:", error);
    }
  };

  const handlepythonScrap = async () => {
    if (!inputpy.trim()) {
      alert("Please enter a valid URL");
      return;
    }

    try {
      setLoading(true);
      // Create form data to send as x-www-form-urlencoded
      const formData = new URLSearchParams();
      formData.append("url", inputpy);
      formData.append("tag", selectedTag);

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/openai-scrap`,
        formData, // Pass form data here
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      setPyres(response.data["html_snippet"]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error scraping the website:", error);
      alert("An error occurred while scraping the website.");
    }
  };

  useEffect(() => {
    if (!("webkitSpeechRecognition" in window)) {
      alert("Your browser does not support speech recognition.");
      return;
    }

    const recognition = new window.webkitSpeechRecognition();
    recognition.lang = "en-US";
    recognition.interimResults = true;
    recognition.continuous = true;

    recognition.onstart = () => {
      setIsListening(true);
    };

    recognition.onend = () => {
      setIsListening(false);
    };

    recognition.onresult = (event) => {
      let transcript = "";
      for (let i = event.resultIndex; i < event.results.length; i++) {
        transcript += event.results[i][0].transcript;
      }
      setInput(transcript);

      if (!isListening) {
        handleSend();
      }
    };

    if (isListening) {
      recognition.start();
    } else {
      recognition.stop();
    }
    return () => {
      recognition.abort();
    };
  }, [isListening]);

  return (
    <>
      <div style={{ padding: "20px", fontFamily: "Arial" }}>
        <h1>rxwiser chatgpt</h1>

        <div
          style={{
            border: "1px solid #ccc",
            borderRadius: "5px",
            padding: "10px",
            height: "300px",
            overflowY: "scroll",
            marginBottom: "10px",
          }}
        >
          {loading ? (
            <>
              <Loader
                type="spinner-default"
                loading={loading}
                bgColor={"black"}
                color={"black"}
                title={"loading..."}
                size={50}
              />
            </>
          ) : (
            <>
              {messages.map((msg, index) => (
                <div key={index} style={{ margin: "5px 0" }}>
                  <strong>{msg.role === "user" ? "You:" : "ChatGPT:"}</strong>
                  <p>{msg.content}</p>
                </div>
              ))}
              {scrap}
              {pyres}
            </>
          )}
        </div>
        <div>
          {/* File Upload */}
          <button
            onClick={() => setIsListening((prev) => !prev)}
            style={{
              padding: "10px 20px",
              backgroundColor: isListening ? "red" : "green",
              color: "white",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
              marginLeft: "10px",
              marginTop: "5px",
            }}
          >
            {isListening ? "Stop Recording" : "Start Recording"}
          </button>

          <input
            type="file"
            onChange={handleFileUpload}
            style={{
              padding: "10px",
              marginRight: "10px",
              borderRadius: "5px",
              border: "1px solid #ccc",
            }}
          />

          {/* Text Input */}
          <input
            type="text"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder="Type your message here..."
            style={{
              padding: "10px",
              width: "40%",
              marginRight: "10px",
              borderRadius: "5px",
              border: "1px solid #ccc",
            }}
          />

          {/* Send Button */}
          <button
            onClick={handleSend}
            style={{
              padding: "10px 20px",
              backgroundColor: "#007bff",
              color: "white",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
            }}
          >
            Send
          </button>

          {/* Audio Recording Button */}
        </div>
        <div style={{ margin: "5px" }}>
          <label>Web scrapping with openAI</label>
          <input
            type="text"
            value={scrapinput}
            onChange={(e) => setScrapinput(e.target.value)}
            placeholder="Type your message here..."
            style={{
              padding: "10px",
              width: "40%",
              marginRight: "10px",
              borderRadius: "5px",
              border: "1px solid #ccc",
              marginLeft: "5px",
            }}
          />

          {/* Send Button */}
          <button
            onClick={handleScrapping}
            style={{
              padding: "10px 20px",
              backgroundColor: "#007bff",
              color: "white",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
            }}
          >
            Send
          </button>
        </div>
        <div style={{ margin: "5px" }}>
          <label>Web scraping with Python</label>
          <input
            type="text"
            value={inputpy}
            onChange={(e) => setInputpy(e.target.value)}
            placeholder="Type your message here..."
            style={{
              padding: "10px",
              width: "40%",
              marginRight: "10px",
              borderRadius: "5px",
              marginLeft: "5px",
              border: "1px solid #ccc",
            }}
          />

          {/* Dropdown for selecting tag type */}
          <select
            value={selectedTag}
            onChange={(e) => setSelectedTag(e.target.value)}
            style={{
              padding: "10px",
              marginRight: "10px",
              borderRadius: "5px",
              border: "1px solid #ccc",
            }}
          >
            <option value="all">All</option>
            <option value="body">body</option>
            <option value="head">head</option>
            <option value="div">div</option>
            <option value="h1">h1</option>
            <option value="h2">h2</option>
            <option value="a">a</option>
            <option value="p">p</option>
            <option value="span">span</option>
          </select>

          {/* Send Button */}
          <button
            onClick={handlepythonScrap}
            style={{
              padding: "10px 20px",
              backgroundColor: "#007bff",
              color: "white",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
            }}
          >
            Send
          </button>
        </div>
      </div>
    </>
  );
}
