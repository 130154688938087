import React, { useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Loader from "react-js-loader";

export default function Register() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    setError("");

    if (!name || !email || !password || !confirmPassword) {
      setError("All fields are required.");
      return;
    }

    if (password !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    try {
      setLoading(true);
      await axios
        .post(`${process.env.REACT_APP_API_URL}/api/register`, {
          name,
          email,
          password,
        })
        .then(function (response) {
          if (response.data.statusCode === 201) {
            setLoading(true);
            navigate("/login");
          }
        })
        .catch(function (error) {
          setLoading(false);
        });
    } catch (error) {
      setError("Registration failed. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <main className="main" id="top">
        <div className="register-main-container">
          <div className="row flex-center min-vh-100">
            <div className="col-sm-10 col-md-8 col-lg-5 col-xl-5 col-xxl-3">
              <Link className="d-flex flex-center text-decoration-none">
                <div className="d-flex align-items-center fw-bolder fs-3 d-inline-block">
                  {/* <img
                    src="img/cover20240825061625.png"
                    alt="Medical App"
                    width={58}
                  /> */}
                </div>
              </Link>
              {loading ? (
                <>
                  <Loader
                    type="spinner-default"
                    loading={loading}
                    bgColor={"black"}
                    color={"black"}
                    title={"loading..."}
                    size={50}
                  />
                </>
              ) : (
                <>
                  <div className="text-center mb-7">
                    <h3 className="text-body-highlight">Sign Up</h3>
                    <p className="text-body-tertiary">
                      Create your account today
                    </p>
                  </div>
                  <div className="position-relative">
                    <hr className="bg-body-secondary" />
                    <div className="divider-content-center">or use email</div>
                  </div>
                  <form onSubmit={handleSubmit}>
                    <div className="mb-3 text-start">
                      <label className="form-label">Name</label>
                      <input
                        className="form-control"
                        id="name"
                        type="text"
                        placeholder="Name"
                        name="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                      />
                    </div>
                    <div className="mb-3 text-start">
                      <label className="form-label">Email address</label>
                      <input
                        className="form-control"
                        id="email"
                        type="email"
                        placeholder="name@example.com"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </div>
                    <div className="row g-3 mb-3">
                      <div className="col-sm-6">
                        <label className="form-label">Password</label>
                        <div
                          className="position-relative"
                          data-password="data-password"
                        >
                          <input
                            className="form-control form-icon-input pe-6"
                            id="password"
                            type="password"
                            placeholder="Password"
                            name="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <label className="form-label">Confirm Password</label>
                        <div
                          className="position-relative"
                          data-password="data-password"
                        >
                          <input
                            className="form-control form-icon-input pe-6"
                            id="confirmPassword"
                            type="password"
                            placeholder="Confirm Password"
                            name="confirmPassword"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    {error && <div className="alert alert-danger">{error}</div>}
                    <div className="form-check mb-3">
                      <input
                        className="form-check-input"
                        id="termsService"
                        type="checkbox"
                        required
                      />
                      <label
                        className="form-label fs-9 text-transform-none"
                        htmlFor="termsService"
                      >
                        I accept the <a>terms </a>and
                        <a> privacy policy</a>
                      </label>
                    </div>

                    <button
                      type="submit"
                      className="btn btn-primary w-100 mb-3 fs-9"
                    >
                      Sign up
                    </button>
                    <div className="text-center">
                      <Link className="fs-9 fw-bold" to="/login">
                        Sign in to an existing account
                      </Link>
                    </div>
                  </form>
                </>
              )}
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
