import React from 'react';

import AdminHeader from "../admin-header/AdminHeader";

export default function PurchaseHistory() {
  return (
      <>
        <main className="main" id="top">
        <AdminHeader />
          <div className="content">
            <h2 className="fs-6  mb-4">Purchase Hisotry</h2>
            <div id="members">
              <div className="mx-n4 mx-lg-n6 px-4 px-lg-6 mb-9 bg-white border-y border-300 mt-2 position-relative top-1">
                <div className="table-responsive scrollbar ms-n1 ps-1">
                  <table
                    className="table table-sm fs--1 mb-0"
                    style={{
                      fontFamily:
                        "SF Pro Text, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif",
                    }}
                  >
                    <thead>
                      <tr>
                        <th
                          className="sort align-middle"
                          scope="col"
                          data-sort="sr"
                        >
                          No
                        </th>
                        <th
                          className="sort align-middle"
                          scope="col"
                          data-sort="interest"
                        >
                          Date
                        </th>
                        <th
                          className="sort align-middle"
                          scope="col"
                          data-sort="customer"
                        >
                          Package
                        </th>
                        <th
                          className="sort align-middle"
                          scope="col"
                          data-sort="interest"
                        >
                          Time
                        </th>
                        <th
                          className="sort align-middle"
                          scope="col"
                          data-sort="interest"
                        >
                          Amount
                        </th>
                        <th
                          className="sort align-middle"
                          scope="col"
                          data-sort="interest"
                        >
                          Description
                        </th>
                      </tr>
                    </thead>
                    <tbody className="list" id="members-table-body">
                      <tr className="hover-actions-trigger btn-reveal-trigger position-static">
                        <td className="sr align-middle white-space-nowrap">
                          1
                        </td>
                        <td className="customer align-middle white-space-nowrap">
                          2024-08-02 07:04:51
                        </td>
                        <td className="customer align-middle white-space-nowrap">
                          Standard Plan
                        </td>
                        <td className="interest align-middle white-space-nowrap">
                          02:46:40
                        </td>
                        <td className="interest align-middle white-space-nowrap">
                          100
                        </td>
                        <td className="interest align-middle white-space-nowrap">
                          Purchase
                        </td>
                      </tr>
                      <tr className="hover-actions-trigger btn-reveal-trigger position-static">
                        <td className="sr align-middle white-space-nowrap">
                          2
                        </td>
                        <td className="customer align-middle white-space-nowrap">
                          2024-08-02 07:04:51
                        </td>
                        <td className="customer align-middle white-space-nowrap">
                          Standard Plan
                        </td>
                        <td className="interest align-middle white-space-nowrap">
                          00:01:40
                        </td>
                        <td className="interest align-middle white-space-nowrap">
                          400
                        </td>
                        <td className="interest align-middle white-space-nowrap">
                          Purchase
                        </td>
                      </tr>
                      <tr className="hover-actions-trigger btn-reveal-trigger position-static">
                        <td className="sr align-middle white-space-nowrap">
                          3
                        </td>
                        <td className="customer align-middle white-space-nowrap">
                          2024-08-02 07:04:51
                        </td>
                        <td className="customer align-middle white-space-nowrap">
                          Standard Plan
                        </td>
                        <td className="interest align-middle white-space-nowrap">
                          00:01:40
                        </td>
                        <td className="interest align-middle white-space-nowrap">
                          600
                        </td>
                        <td className="interest align-middle white-space-nowrap">
                          Purchase
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <footer className="footer position-absolute">
              <div className="row g-0 justify-content-between align-items-center h-100">
                <div className="col-12 col-sm-auto text-center">
                  <p className="mb-0 mt-2 mt-sm-0 text-900">
                    All rights reserved
                    <span className="d-none d-sm-inline-block" />
                    <span className="d-none d-sm-inline-block mx-1">|</span>
                    <br className="d-sm-none" />
                    2024 ©
                    {/* <a className="mx-1" href="#">
                      Medical App
                    </a> */}
                  </p>
                </div>
              </div>
            </footer>
          </div>
        </main>
      </>
  );
}
