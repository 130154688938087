import React, { useEffect, useState } from "react";
import AdminHeader from "../admin-header/AdminHeader";
import axios from "axios";
import { BrowserRouter as Router, Link, useNavigate } from "react-router-dom";
import NewTemplates from "./NewTemplates";
import ReactDOM from "react-dom";
import Loader from "react-js-loader";

export default function Templates() {
  const navigate = useNavigate();
  const [templates, setTemplates] = useState([]);
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editingTemplate, setEditingTemplate] = useState(null);


  const toggle = (isEdit = false, template = null) => {
    console.log("1");
    if (!isEdit) {
      setEditingTemplate(null);
    } else {
      setEditingTemplate(template);
    }
    setModal(!modal);
  };

  const handleDelete = (id) => {
    axios
      .delete(`${process.env.REACT_APP_API_URL}/api/dr_templates/${id}`)
      .then(() => {
        console.log("Template deleted successfully");
        fetechTemplates();
      })
      .catch((error) => {
        console.error("Error deleting template:", error);
      });
  };

  const fetechTemplates = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/dr_templates`
      );
      setTemplates(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching templates:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetechTemplates();
  }, []);



  return (
    <>
      <main className="main" id="top">
        <AdminHeader />
        <div className="content">
          <div className="row align-items-center justify-content-between g-2">
            <div className="col col-auto">
              <div className="d-flex align-items-center">
                <h2 className="fs-6 mb-3">Templates</h2>
              </div>
            </div>
            <div className="col-auto">
              <div className="d-flex align-items-center">
                <button
                  className="btn btn-primary"
                  onClick={() => toggle(false)}
                >
                  <span className="fas fa-plus me-2" />
                  Add New
                </button>
              </div>
            </div>
            <NewTemplates
              isOpen={modal}
              toggle={toggle}
              fetechTemplates={fetechTemplates}
              editingTemplate={editingTemplate}
            />
          </div>
          <div id="members">
            <div className="mx-n4 mx-lg-n6 px-4 px-lg-6 mb-9 bg-white border-y border-300 mt-2 position-relative top-1">
              <div className="table-responsive scrollbar ms-n1 ps-1">
                <table
                  className="table table-sm fs--1 mb-0"
                  style={{
                    fontFamily:
                      "SF Pro Text, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif",
                  }}
                >
                  <thead>
                    <tr>
                      <th
                        className="sort align-middle"
                        scope="col"
                        data-sort="sr"
                      >
                        Sr
                      </th>
                      <th
                        className="sort align-middle"
                        scope="col"
                        data-sort="customer"
                      >
                        Template
                      </th>
                      <th
                        className="sort align-middle"
                        scope="col"
                        data-sort="interest"
                      >
                        Details
                      </th>
                      <th
                        className="sort align-middle"
                        scope="col"
                        data-sort="customer"
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody className="list" id="members-table-body">
                    {loading ? (
                      <>
                        <div
                          style={{
                            float: "right",
                            alignItems: "center",
                          }}
                        >
                          <Loader
                            type="spinner-default"
                            loading={loading}
                            bgColor={"black"}
                            title={"loading..."}
                            size={50}
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        {templates.map((temp, index) => {
                          const plainText = temp.details.replace(
                            /<[^>]*>/g,
                            ""
                          );
                          return (
                            <>
                              <tr
                                key={temp.id}
                                className="hover-actions-trigger btn-reveal-trigger position-static"
                              >
                                <td className="sr align-middle white-space-nowrap">
                                  {index + 1}
                                </td>
                                <td className="customer align-middle white-space-nowrap">
                                  {temp.heading || "Null"}
                                </td>
                                <td className="interest align-middle white-space-nowrap">
                                  {plainText}
                                </td>
                                <td>
                                  <div className="font-sans-serif btn-reveal-trigger position-static">
                                    <i
                                      className="fa fa-edit"
                                      onClick={() => toggle(true, temp)}
                                      style={{
                                        marginLeft: "5px",
                                        cursor: "pointer",
                                      }}
                                    />
                                    <i
                                      className="fa fa-trash"
                                      style={{
                                        marginLeft: "5px",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => handleDelete(temp.id)}
                                    />
                                  </div>
                                </td>
                              </tr>
                            </>
                          );
                        })}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="row align-items-center justify-content-between py-2 pe-0 fs--1">
                <div className="col-auto d-flex">
                  <p
                    className="mb-0 d-none d-sm-block me-3 fw-semi-bold text-900"
                    data-list-info="data-list-info"
                  />
                  <a className="fw-semi-bold" data-list-view="*">
                    View all
                    <span
                      className="fas fa-angle-right ms-1"
                      data-fa-transform="down-1"
                    />
                  </a>
                  <a className="fw-semi-bold d-none" data-list-view="less">
                    View Less
                    <span
                      className="fas fa-angle-right ms-1"
                      data-fa-transform="down-1"
                    />
                  </a>
                </div>
                <div className="col-auto d-flex">
                  <button className="page-link" data-list-pagination="prev">
                    <span className="fas fa-chevron-left" />
                  </button>
                  <ul className="mb-0 pagination" />
                  <button
                    className="page-link pe-0"
                    data-list-pagination="next"
                  >
                    <span className="fas fa-chevron-right" />
                  </button>
                </div>
              </div>
            </div>
          </div>

          <footer className="footer position-absolute">
            <div className="row g-0 justify-content-between align-items-center h-100">
              <div className="col-12 col-sm-auto text-center">
                <p className="mb-0 mt-2 mt-sm-0 text-900">
                  All rights reserved
                  <span className="d-none d-sm-inline-block" />
                  <span className="d-none d-sm-inline-block mx-1">|</span>
                  <br className="d-sm-none" />
                  2024 ©<a className="mx-1">Medical App</a>
                </p>
              </div>
            </div>
          </footer>
        </div>
      </main>
    </>
  );
}
