import React from 'react';


import Home from "./home/Home";
import Social from "./social/Social";
import Features from "./features/Features";
import Testimonial from './testimonial/Testimonial';
import Pricing from './pricing/Pricing';
import Blog from './blog/Blog';
import Address from './address/Address';
import Team from './team/Team';
import Subscribe from "./subscribe/Subscribe";
import Footer from "../shared/components/Footer";

export default function Website() {
  return (
    <>
      <Home />
      <Social />
      <Features />
      <Testimonial />
      <Pricing />
      <Blog />
      <Address />
      <Team />
      <Subscribe />
      <Footer />
    </>
  );
}
