import React from 'react';

import AdminHeader from "../admin-header/AdminHeader";

export default function User() {
  return (
    <>
      <main className="main" id="top">
       <AdminHeader />
        <div className="content">
          <h2 className="fs-6 text-1100 mb-5">Users</h2>
          <div id="members">
            <div className="mx-n4 mx-lg-n6 px-4 px-lg-6 mb-9 bg-white border-y border-300 mt-2 position-relative top-1">
              <div className="table-responsive scrollbar ms-n1 ps-1">
                <table
                  className="table table-sm fs--1 mb-0"
                  style={{
                    fontFamily:
                      "SF Pro Text, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif",
                  }}
                >
                  <thead>
                    <tr>
                      <th
                        className="sort align-middle"
                        scope="col"
                        data-sort="sr"
                      >
                        No
                      </th>
                      <th
                        className="sort align-middle"
                        scope="col"
                        data-sort="customer"
                      >
                        Name
                      </th>
                      <th
                        className="sort align-middle"
                        scope="col"
                        data-sort="email"
                      >
                        Email
                      </th>
                      <th
                        className="sort align-middle"
                        scope="col"
                        data-sort="phone"
                      >
                        Phone
                      </th>
                      <th
                        className="sort align-middle"
                        scope="col"
                        data-sort="state"
                      >
                        Status
                      </th>
                      <th
                        className="sort align-middle"
                        scope="col"
                        data-sort="customer"
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody className="list" id="members-table-body">
                    <tr className="hover-actions-trigger btn-reveal-trigger position-static">
                      <td className="sr align-middle white-space-nowrap">1</td>
                      <td className="customer align-middle white-space-nowrap">
                        Shoaib Khan
                      </td>
                      <td className="email align-middle white-space-nowrap">
                        admin@admin.com
                      </td>
                      <td className="phone align-middle white-space-nowrap">
                        03355883030
                      </td>
                      <td className="state align-middle white-space-nowrap">
                        ACTIVE
                      </td>
                      <td>
                        <div className="font-sans-serif btn-reveal-trigger position-static">
                          <i
                            className="fa fa-edit"
                            style={{ cursor: "pointer" }}
                          />
                          <i
                            className="fa fa-trash"
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr className="hover-actions-trigger btn-reveal-trigger position-static">
                      <td className="sr align-middle white-space-nowrap">2</td>
                      <td className="customer align-middle white-space-nowrap">
                        junaid -
                      </td>
                      <td className="email align-middle white-space-nowrap">
                        asherjunaid@gmail.com
                      </td>
                      <td className="phone align-middle white-space-nowrap" />
                      <td className="state align-middle white-space-nowrap">
                        ACTIVE
                      </td>
                      <td>
                        <div className="font-sans-serif btn-reveal-trigger position-static">
                          <i
                            className="fa fa-edit"
                            style={{ cursor: "pointer" }}
                          />
                          <i
                            className="fa fa-trash"
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr className="hover-actions-trigger btn-reveal-trigger position-static">
                      <td className="sr align-middle white-space-nowrap">3</td>
                      <td className="customer align-middle white-space-nowrap">
                        junaid -
                      </td>
                      <td className="email align-middle white-space-nowrap">
                        asgherjunaid@gmail.com
                      </td>
                      <td className="phone align-middle white-space-nowrap" />
                      <td className="state align-middle white-space-nowrap">
                        ACTIVE
                      </td>
                      <td>
                        <div className="font-sans-serif btn-reveal-trigger position-static">
                          <i
                            className="fa fa-edit"
                            style={{ cursor: "pointer" }}
                          />
                          <i
                            className="fa fa-trash"
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr className="hover-actions-trigger btn-reveal-trigger position-static">
                      <td className="sr align-middle white-space-nowrap">4</td>
                      <td className="customer align-middle white-space-nowrap">
                        Adnan Siraj
                      </td>
                      <td className="email align-middle white-space-nowrap">
                        adnanwadani2626@gmail.com
                      </td>
                      <td className="phone align-middle white-space-nowrap" />
                      <td className="state align-middle white-space-nowrap">
                        ACTIVE
                      </td>
                      <td>
                        <div className="font-sans-serif btn-reveal-trigger position-static">
                          <i
                            className="fa fa-edit"
                            style={{ cursor: "pointer" }}
                          />
                          <i
                            className="fa fa-trash"
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr className="hover-actions-trigger btn-reveal-trigger position-static">
                      <td className="sr align-middle white-space-nowrap">5</td>
                      <td className="customer align-middle white-space-nowrap">
                        wadani -
                      </td>
                      <td className="email align-middle white-space-nowrap">
                        wadani@gmail.com
                      </td>
                      <td className="phone align-middle white-space-nowrap" />
                      <td className="state align-middle white-space-nowrap">
                        INACTIVE
                      </td>
                      <td>
                        <div className="font-sans-serif btn-reveal-trigger position-static">
                          <i
                            className="fa fa-edit"
                            style={{ cursor: "pointer" }}
                          />
                          <i
                            className="fa fa-trash"
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr className="hover-actions-trigger btn-reveal-trigger position-static">
                      <td className="sr align-middle white-space-nowrap">6</td>
                      <td className="customer align-middle white-space-nowrap">
                        wadani -
                      </td>
                      <td className="email align-middle white-space-nowrap">
                        wadani4582@gmail.com
                      </td>
                      <td className="phone align-middle white-space-nowrap" />
                      <td className="state align-middle white-space-nowrap">
                        INACTIVE
                      </td>
                      <td>
                        <div className="font-sans-serif btn-reveal-trigger position-static">
                          <i
                            className="fa fa-edit"
                            style={{ cursor: "pointer" }}
                          />
                          <i
                            className="fa fa-trash"
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr className="hover-actions-trigger btn-reveal-trigger position-static">
                      <td className="sr align-middle white-space-nowrap">7</td>
                      <td className="customer align-middle white-space-nowrap">
                        wadani -
                      </td>
                      <td className="email align-middle white-space-nowrap">
                        wadanibaloch4582@gmail.com
                      </td>
                      <td className="phone align-middle white-space-nowrap" />
                      <td className="state align-middle white-space-nowrap">
                        ACTIVE
                      </td>
                      <td>
                        <div className="font-sans-serif btn-reveal-trigger position-static">
                          <i
                            className="fa fa-edit"
                            style={{ cursor: "pointer" }}
                          />
                          <i
                            className="fa fa-trash"
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr className="hover-actions-trigger btn-reveal-trigger position-static">
                      <td className="sr align-middle white-space-nowrap">8</td>
                      <td className="customer align-middle white-space-nowrap">
                        Wadani Baloch
                      </td>
                      <td className="email align-middle white-space-nowrap">
                        balochwadani83@gmail.com
                      </td>
                      <td className="phone align-middle white-space-nowrap" />
                      <td className="state align-middle white-space-nowrap">
                        ACTIVE
                      </td>
                      <td>
                        <div className="font-sans-serif btn-reveal-trigger position-static">
                          <i
                            className="fa fa-edit"
                            style={{ cursor: "pointer" }}
                          />
                          <i
                            className="fa fa-trash"
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr className="hover-actions-trigger btn-reveal-trigger position-static">
                      <td className="sr align-middle white-space-nowrap">9</td>
                      <td className="customer align-middle white-space-nowrap">
                        Wadani Baloch
                      </td>
                      <td className="email align-middle white-space-nowrap">
                        wadaniwadani4582@gmail.com
                      </td>
                      <td className="phone align-middle white-space-nowrap" />
                      <td className="state align-middle white-space-nowrap">
                        ACTIVE
                      </td>
                      <td>
                        <div className="font-sans-serif btn-reveal-trigger position-static">
                          <i
                            className="fa fa-edit"
                            style={{ cursor: "pointer" }}
                          />
                          <i
                            className="fa fa-trash"
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr className="hover-actions-trigger btn-reveal-trigger position-static">
                      <td className="sr align-middle white-space-nowrap">10</td>
                      <td className="customer align-middle white-space-nowrap">
                        Leslie -
                      </td>
                      <td className="email align-middle white-space-nowrap">
                        leslie@incusservices.com
                      </td>
                      <td className="phone align-middle white-space-nowrap" />
                      <td className="state align-middle white-space-nowrap">
                        ACTIVE
                      </td>
                      <td>
                        <div className="font-sans-serif btn-reveal-trigger position-static">
                          <i
                            className="fa fa-edit"
                            style={{ cursor: "pointer" }}
                          />
                          <i
                            className="fa fa-trash"
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr className="hover-actions-trigger btn-reveal-trigger position-static">
                      <td className="sr align-middle white-space-nowrap">11</td>
                      <td className="customer align-middle white-space-nowrap">
                        khan -
                      </td>
                      <td className="email align-middle white-space-nowrap">
                        cherish35@belgianairways.com
                      </td>
                      <td className="phone align-middle white-space-nowrap" />
                      <td className="state align-middle white-space-nowrap">
                        ACTIVE
                      </td>
                      <td>
                        <div className="font-sans-serif btn-reveal-trigger position-static">
                          <i
                            className="fa fa-edit"
                            style={{ cursor: "pointer" }}
                          />
                          <i
                            className="fa fa-trash"
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr className="hover-actions-trigger btn-reveal-trigger position-static">
                      <td className="sr align-middle white-space-nowrap">12</td>
                      <td className="customer align-middle white-space-nowrap">
                        Leslie Lee Fook
                      </td>
                      <td className="email align-middle white-space-nowrap">
                        leslieleefook@gmail.com
                      </td>
                      <td className="phone align-middle white-space-nowrap" />
                      <td className="state align-middle white-space-nowrap">
                        ACTIVE
                      </td>
                      <td>
                        <div className="font-sans-serif btn-reveal-trigger position-static">
                          <i
                            className="fa fa-edit"
                            style={{ cursor: "pointer" }}
                          />
                          <i
                            className="fa fa-trash"
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr className="hover-actions-trigger btn-reveal-trigger position-static">
                      <td className="sr align-middle white-space-nowrap">13</td>
                      <td className="customer align-middle white-space-nowrap">
                        shoaib new email -
                      </td>
                      <td className="email align-middle white-space-nowrap">
                        8marni@starmail.net
                      </td>
                      <td className="phone align-middle white-space-nowrap" />
                      <td className="state align-middle white-space-nowrap">
                        ACTIVE
                      </td>
                      <td>
                        <div className="font-sans-serif btn-reveal-trigger position-static">
                          <i
                            className="fa fa-edit"
                            style={{ cursor: "pointer" }}
                          />
                          <i
                            className="fa fa-trash"
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr className="hover-actions-trigger btn-reveal-trigger position-static">
                      <td className="sr align-middle white-space-nowrap">14</td>
                      <td className="customer align-middle white-space-nowrap">
                        muhammad shoaibwadani
                      </td>
                      <td className="email align-middle white-space-nowrap">
                        muhammadshoaibwadani@gmail.com
                      </td>
                      <td className="phone align-middle white-space-nowrap" />
                      <td className="state align-middle white-space-nowrap">
                        ACTIVE
                      </td>
                      <td>
                        <div className="font-sans-serif btn-reveal-trigger position-static">
                          <i
                            className="fa fa-edit"
                            style={{ cursor: "pointer" }}
                          />
                          <i
                            className="fa fa-trash"
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="row align-items-center justify-content-between py-2 pe-0 fs--1">
                <div className="col-auto d-flex">
                  <p
                    className="mb-0 d-none d-sm-block me-3 fw-semi-bold text-900"
                    data-list-info="data-list-info"
                  />
                  <a className="fw-semi-bold" data-list-view="*">
                    View all
                    <span
                      className="fas fa-angle-right ms-1"
                      data-fa-transform="down-1"
                    />
                  </a>
                  <a
                    className="fw-semi-bold d-none"
                    data-list-view="less"
                  >
                    View Less
                    <span
                      className="fas fa-angle-right ms-1"
                      data-fa-transform="down-1"
                    />
                  </a>
                </div>
                <div className="col-auto d-flex">
                  <button className="page-link" data-list-pagination="prev">
                    <span className="fas fa-chevron-left" />
                  </button>
                  <ul className="mb-0 pagination" />
                  <button
                    className="page-link pe-0"
                    data-list-pagination="next"
                  >
                    <span className="fas fa-chevron-right" />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <footer className="footer position-absolute">
            <div className="row g-0 justify-content-between align-items-center h-100">
              <div className="col-12 col-sm-auto text-center">
                <p className="mb-0 mt-2 mt-sm-0 text-900">
                  All rights reserved
                  <span className="d-none d-sm-inline-block" />
                  <span className="d-none d-sm-inline-block mx-1">|</span>
                  <br className="d-sm-none" />
                  2024 ©
                  <a className="mx-1" >
                    Medical App
                  </a>
                </p>
              </div>
            </div>
          </footer>
        </div>
      </main>
    </>
  );
}
