import React from 'react';

export default function HiddenFields() {
  return (
    <>
      <input
        type="hidden"
        id="access_id"
        placeholder="ACCESS ID"
        value="AKIAX5ZI57UBWIKJQOW4"
      />
      <input
        type="hidden"
        id="secret_key"
        placeholder="SECRET KEY"
        value="slQcpML5RIQieftBJp6NAfuo/B3LDjITOyYiFZp6"
      />
      <input
        type="hidden"
        id="session_token"
        placeholder="SESSION TOKEN"
        value=""
      />
      <input
        type="hidden"
        id="region"
        placeholder="SESSION TOKEN"
        value="ap-south-1"
      />
    </>
  );
}
