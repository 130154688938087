import React from 'react';
import { useEffect, useState } from "react";
import axios from "axios";
import {
  BrowserRouter as Router,
  useLocation,
  useNavigate,
} from "react-router-dom";

import AdminHeader from "../admin-header/AdminHeader";
import $ from "jquery";
import ChatgptIntegration from "./ChatgptIntegration";
import ButtonSection from "./ButtonSection";
import DynamicTab from "./DynamicTab";
import HiddenFields from "./HiddenFields";
window.$ = $;

export default function TranscriptionHistory() {
  const navigate = useNavigate();

  const [selectedTemplate, setSelectedTemplate] = useState("12");
  const [selectedLanguage, setSelectedLanguage] = useState("en-US");
  const [patientName, setPatientName] = useState("Default Patient");
  const [recordingStatus, setRecordingStatus] = useState("");
  const [isRecording, setIsRecording] = useState(false);
  const [audioTime, setAudioTime] = useState(0);
  const [intervalId, setIntervalId] = useState(null);
  const [templates, setTemplates] = useState([]);
  const [tabdata, setTabdata] = useState([]);
  const [randomNumber, setRandomNumber] = useState();

  const currentUrl = useLocation().pathname.replace(/^\//, "").split("/")[2];

  const fetechTemplates = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/dr_templates`
      );
      setTemplates(response.data);
      if (response.data && response.data.length > 0) {
        setSelectedTemplate(response.data[0].id);
      }
    } catch (error) {
      console.error("Error fetching templates:", error);
    }
  };

  const generateRandomNumber = () => {
    return Math.floor(10000000 + Math.random() * 90000000).toString();
  };

  useEffect(() => {
    fetechTemplates();
    const script = document.createElement("script");
    script.src = `${process.env.PUBLIC_URL}/assets/js/main.js`;
    script.async = true;
    document.body.appendChild(script);

    if (currentUrl) {
      setRandomNumber(currentUrl);
      getRandomNumber(currentUrl);
    } else {
      const newRandom = generateRandomNumber();
      setRandomNumber(newRandom);
      getRandomNumber(newRandom);
    }

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const getRandomNumber = (number) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/transcription_submission_check`,
        {
          randomno: number,
        }
      )
      .then((response) => {
        setTabdata(response.data.formatted_text);
        setPatientName(response.data.patient_name || "");
        document.getElementById("contentToCopy").value = response.data.text;
        document.getElementById("transcript").value = response.data.text;
      })
      .catch((error) => {
        console.error("Error getting random number:", error);
      });
  };

  const handleTemplateChange = () => {
    let val = document.getElementById("contentToCopy").value;
    if (val.length) {
      const payload = {
        text: val,
        randomno: randomNumber,
        template: selectedTemplate,
      };
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/api/transcription_submission_temp`,
          payload
        )
        .then((response) => {
          if (response.data.statusCode === 201) {
            getRandomNumber(randomNumber);
          }
        })
        .catch((error) => {
          console.error("Error saving new template:", error);
        });
    } else if (!val.length) {
      return alert("Please Add your details");
    }
  };

  const handleLanguageChange = (event) => {
    setSelectedLanguage(event.target.value);
  };

  const handlePatientNameChange = (event) => {
    setPatientName(event.target.value);
  };

  const handleRecordingToggle = () => {
    if (!isRecording) {
      const interval = setInterval(() => {
        setAudioTime((prevTime) => prevTime + 1);
      }, 1000);
      setIntervalId(interval);
      setIsRecording(true);
    } else {
      clearInterval(intervalId);
      setIntervalId(null);
      setIsRecording(false);

      handleStopButtonClick();
    }
  };

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const sec = seconds % 60;
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${sec.toString().padStart(2, "0")}`;
  };

  const handleStopButtonClick = () => {
    if (isRecording) {
      const transcriptValue = document.getElementById("transcript").value;

      const payload = {
        text: transcriptValue,
        template: selectedTemplate,
        language: selectedLanguage,
        randomno: randomNumber,
        total_time: audioTime,
        user_id: localStorage.getItem("user_id"),
        patient_name: patientName,
      };
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/api/transcription_submission`,
          payload
        )
        .then((response) => {
          setTabdata(response.data.formatted_text);
          navigate(`/admin/session/${randomNumber}`);
        })
        .catch((error) => {
          console.error("Error saving template:", error);
        });
    }
  };

  return (
    <main className="main" id="top">
      <AdminHeader />
      <div className="content">
        <div className="row align-items-center justify-content-between g-2">
          <div className="col col-auto">
            <div className="d-flex align-items-center">
              <form
                id="tempadd"
                action="updatetemptext"
                method="POST"
                style={{
                  fontFamily:
                    "SF Pro Text, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif",
                }}
              >

                <div className="form-icon-container">
                  <div className="form-floating mb-3">
                    <select
                      name="myowntemp"
                      id="tempa"
                      className="form-control form-icon-input"
                      value={selectedTemplate}
                      onChange={(e) => {
                        setSelectedTemplate(e.target.value);
                        handleTemplateChange();
                      }}
                    >
                      {Array.isArray(templates) && templates.length > 0 ? (
                        templates?.map((template) => (
                          <option key={template.id} value={template.id}>
                            {template.heading}{" "}
                          </option>
                        ))
                      ) : (
                        <option>Loading templates...</option>
                      )}
                    </select>
                    <label className="form-icon-label" htmlFor="tempa">
                      Template
                    </label>
                  </div>
                </div>
              </form>

              <div className="form-icon-container">
                <div className="form-floating mb-3">
                  <select
                    name="lng"
                    id="lng"
                    className="form-control form-icon-input"
                    value={selectedLanguage}
                    onChange={handleLanguageChange}
                  >
                    <option value="en-US">English US</option>
                    <option value="en-GB">English GB</option>
                    <option value="en-AU">English AU</option>
                    <option value="ar-SA">Arabic</option>
                    <option value="es-ES">Spanish</option>
                    <option value="de-DE">German</option>
                    <option value="fr-FR">French</option>
                    <option value="it-IT">Italian</option>
                  </select>
                  <label className="form-icon-label" htmlFor="floatingInput">
                    Language
                  </label>
                </div>
              </div>

              <div className="form-icon-container">
                <div className="form-floating mb-3">
                  <input
                    className="form-control form-icon-input"
                    id="ptname"
                    type="text"
                    value={patientName}
                    onChange={handlePatientNameChange}
                  />
                  <label className="form-icon-label" htmlFor="floatingInput">
                    Patient Name
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className="col-auto">
            <div className="d-flex align-items-center">
              <button
                className="btn btn-phoenix-secondary me-1 mb-1"
                type="button"
                id="timer"
              >
                {formatTime(audioTime)}
              </button>
              <div className="btn-group mb-1 me-1">
                <button
                  className="btn btn-success"
                  type="button"
                  id={isRecording ? "stop-button" : "start-button"}
                  onClick={handleRecordingToggle}
                >
                  {isRecording ? "Stop Transcribing" : "Start Transcribing"}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div id="liveaudio">
          <div className="row mt-1">
            <div className="col-md-2">
              <input type="hidden" name="title" value="" id="title" />
              <input
                type="hidden"
                id="temps"
                value={selectedTemplate}
                name="temps"
              />
              <input
                type="hidden"
                name="randomno"
                value="92808722097517735"
                className="form-control"
                id="randomno"
              />
              <input type="hidden" id="audiotime" name="audiotime" />
            </div>
          </div>
          <p>{recordingStatus}</p>
          <hr />
        </div>
        <HiddenFields />
        <DynamicTab tabdata={tabdata} />
        <ChatgptIntegration />
      </div>
    </main>
  );
}
