import React from 'react';

export default function Team() {
  return (
    <>
      <section id="team">
        <div
          className="bg-holder z-2"
          style={{
            backgroundImage: "url(assets/img/bg/bg-left-17.png)",
            backgroundSize: "auto",
            backgroundPosition: "left center",
          }}
        ></div>
        <div
          className="bg-holder z-2"
          style={{
            backgroundImage: "url(assets/img/bg/bg-right-17.png)",
            backgroundSize: "auto",
            backgroundPosition: "right center",
          }}
        ></div>
        <div className="position-absolute top-0 end-0 start-0">
          <svg
            className="w-100 text-white"
            preserveAspectRatio="none"
            viewBox="0 0 1920 368"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              className="fill-emphasis-bg"
              d="M1920 0.44L0 367.74V0H1920V0.44Z"
            ></path>
          </svg>
        </div>
        <div className="position-absolute bottom-0 end-0 start-0">
          <svg
            className="text-white w-100"
            viewBox="0 0 1920 368"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              className="fill-emphasis-bg"
              d="M0 368L1920 0.730011L1920 368L0 368Z"
            ></path>
          </svg>
        </div>
        <div
          className="container-small position-relative py-1 px-lg-7 px-xxl-3"
          style={{
            zIndex: 10,
          }}
        >
          <div className="mb-4 text-center text-sm-start">
            <h4 className="text-primary fa-9 mb-3">Team</h4>
            <h2 className="fa-9" style={{ fontSize: "28px" }}>
              Our small team behind our success
            </h2>
          </div>
          <p className="column-md-2 text-center fa-9 text-sm-start">
            We have a small but strong development team to follow up on the
            development process. Reach out to us for further information. The
            team is ready to answer all your questions within minutes. The
            efficient team is always at your beck and call.
          </p>
          <div className="row align-items-center ps-lg-11 pe-lg-9">
            <div className="col-sm-6 col-md-4 col-lg-3">
              <div className="text-center mt-5 position-relative">
                <div className="team-avatar-container d-inline-block position-relative">
                  <div
                    className="bg-holder"
                    style={{
                      backgroundImage: "url(assets/1.png)",
                      backgroundSize: "contain",
                    }}
                  ></div>
                  <img
                    className="img-fluid rounded mb-3 position-relative"
                    src="assets/img/team/62.webp"
                    alt="..."
                  />
                </div>
                <h4 className="fa-9" style={{ fontSize: "18px" }}>
                  John Smith
                </h4>
                <p className="mb-3 fa-9" style={{ fontSize: "0.8rem" }}>
                  CEO, Global Cheat
                </p>
                <a href="#!">
                  <span className="fa-brands fa-facebook text-primary me-3"></span>
                </a>
                <a href="#!">
                  <span className="fa-brands fa-twitter text-primary me-3"></span>
                </a>
                <a href="#!">
                  <span className="fa-brands fa-linkedin-in text-primary"></span>
                </a>
              </div>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-3">
              <div className="text-center mt-5 position-relative">
                <div className="team-avatar-container d-inline-block position-relative">
                  <div
                    className="bg-holder"
                    style={{
                      backgroundImage: "url(assets/img/bg/bg-21.png)",
                      backgroundSize: "contain",
                    }}
                  ></div>
                  <img
                    className="img-fluid rounded mb-3 position-relative"
                    src="assets/img/team/63.webp"
                    alt="..."
                  />
                </div>
                <h4 className="fa-9" style={{ fontSize: "18px" }}>
                  Marc Chiasson
                </h4>
                <p className="mb-3 fa-9" style={{ fontSize: "0.8rem" }}>
                  Vice President
                </p>
                <a href="#!">
                  <span className="fa-brands fa-facebook text-primary me-3"></span>
                </a>
                <a href="#!">
                  <span className="fa-brands fa-twitter text-primary me-3"></span>
                </a>
                <a href="#!">
                  <span className="fa-brands fa-linkedin-in text-primary"></span>
                </a>
              </div>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-3">
              <div className="text-center mt-5 position-relative">
                <div className="team-avatar-container d-inline-block position-relative">
                  <div
                    className="bg-holder"
                    style={{
                      backgroundImage: "url(assets/img/bg/bg-21.png)",
                      backgroundSize: "contain",
                    }}
                  ></div>
                  <img
                    className="img-fluid rounded mb-3 position-relative"
                    src="assets/img/team/64.webp"
                    alt="..."
                  />
                </div>
                <h4 className="fa-9" style={{ fontSize: "18px" }}>
                  Lilah Lola
                </h4>
                <p className="mb-3 fa-9" style={{ fontSize: "0.8rem" }}>
                  Marketing Manager
                </p>
                <a href="#!">
                  <span className="fa-brands fa-facebook text-primary me-3"></span>
                </a>
                <a href="#!">
                  <span className="fa-brands fa-twitter text-primary me-3"></span>
                </a>
                <a href="#!">
                  <span className="fa-brands fa-linkedin-in text-primary"></span>
                </a>
              </div>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-3">
              <div className="text-center mt-5 position-relative">
                <div className="team-avatar-container d-inline-block position-relative">
                  <div
                    className="bg-holder"
                    style={{
                      backgroundImage: "url(assets/img/bg/bg-21.png)",
                      backgroundSize: "contain",
                    }}
                  ></div>

                  <img
                    className="img-fluid rounded mb-3 position-relative"
                    src="assets/img/team/65.webp"
                    alt="..."
                  />
                </div>
                <h4 className="fa-9" style={{ fontSize: "18px" }}>
                  Thomas Doe
                </h4>
                <p className="mb-3 fa-9" style={{ fontSize: ".8rem" }}>
                  UX Designer
                </p>
                <a href="#!">
                  <span className="fa-brands fa-facebook text-primary me-3"></span>
                </a>
                <a href="#!">
                  <span className="fa-brands fa-twitter text-primary me-3"></span>
                </a>
                <a href="#!">
                  <span className="fa-brands fa-linkedin-in text-primary"></span>
                </a>
              </div>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-3">
              <div className="text-center mt-5 position-relative">
                <div className="team-avatar-container d-inline-block position-relative">
                  <div
                    className="bg-holder"
                    style={{
                      backgroundImage: "url(assets/img/bg/bg-21.png)",
                      backgroundSize: "contain",
                    }}
                  ></div>
                  <img
                    className="img-fluid rounded mb-3 position-relative"
                    src="assets/img/team/66.webp"
                    alt="..."
                  />
                </div>
                <h4 className="fa-9" style={{ fontSize: "18px" }}>
                  Alan Casey
                </h4>
                <p className="mb-3 fa-9" style={{ fontSize: "0.8rem" }}>
                  Front End Developer
                </p>
                <a href="#!">
                  <span className="fa-brands fa-facebook text-primary me-3"></span>
                </a>
                <a href="#!">
                  <span className="fa-brands fa-twitter text-primary me-3"></span>
                </a>
                <a href="#!">
                  <span className="fa-brands fa-linkedin-in text-primary"></span>
                </a>
              </div>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-3">
              <div className="text-center mt-5 position-relative">
                <div className="team-avatar-container d-inline-block position-relative">
                  <div
                    className="bg-holder"
                    style={{
                      backgroundImage: "url(assets/img/bg/bg-21.png)",
                      backgroundSize: "contain",
                    }}
                  ></div>
                  <img
                    className="img-fluid rounded mb-3 position-relative"
                    src="assets/img/team/67.webp"
                    alt="..."
                  />
                </div>
                <h4 className="fa-9" style={{ fontSize: "18px" }}>
                  Narokin Hijita
                </h4>
                <p className="mb-3 fa-9" style={{ fontSize: ".8rem" }}>
                  CEO, Global Cheat
                </p>
                <a href="#!">
                  <span className="fa-brands fa-facebook text-primary me-3"></span>
                </a>
                <a href="#!">
                  <span className="fa-brands fa-twitter text-primary me-3"></span>
                </a>
                <a href="#!">
                  <span className="fa-brands fa-linkedin-in text-primary"></span>
                </a>
              </div>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-3">
              <div className="text-center mt-5 position-relative">
                <div className="team-avatar-container d-inline-block position-relative">
                  <div
                    className="bg-holder"
                    style={{
                      backgroundImage: "url(assets/img/bg/bg-21.png)",
                      backgroundSize: "contain",
                    }}
                  ></div>
                  <img
                    className="img-fluid rounded mb-3 position-relative"
                    src="assets/img/team/68.webp"
                    alt="..."
                  />
                </div>
                <h4 className="fa-9" style={{ fontSize: "18px" }}>
                  Narokin Hijita
                </h4>
                <p className="mb-3 fa-9" style={{ fontSize: "0.8rem" }}>
                  CEO, Global Cheat
                </p>
                <a href="#!">
                  <span className="fa-brands fa-facebook text-primary me-3"></span>
                </a>
                <a href="#!">
                  <span className="fa-brands fa-twitter text-primary me-3"></span>
                </a>
                <a href="#!">
                  <span className="fa-brands fa-linkedin-in text-primary"></span>
                </a>
              </div>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-3">
              <div className="text-center mt-5 position-relative">
                <div className="team-avatar-container d-inline-block position-relative">
                  <div
                    className="bg-holder"
                    style={{
                      backgroundImage: "url(assets/img/bg/bg-21.png)",
                      backgroundSize: "contain",
                    }}
                  ></div>
                  <img
                    className="img-fluid rounded mb-3 position-relative"
                    src="assets/img/team/69.webp"
                    alt="..."
                  />
                </div>
                <h4 className="fa-9" style={{ fontSize: "18px" }}>
                  Narokin Hijita
                </h4>
                <p className="mb-3 fa-9" style={{ fontSize: "0.8rem" }}>
                  CEO, Global Cheat
                </p>
                <a href="#!">
                  <span className="fa-brands fa-facebook text-primary me-3"></span>
                </a>
                <a href="#!">
                  <span className="fa-brands fa-twitter text-primary me-3"></span>
                </a>
                <a href="#!">
                  <span className="fa-brands fa-linkedin-in text-primary"></span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
