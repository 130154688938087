import React from 'react';
import { useState } from "react";
import { TabPane, TabContent, NavLink, Nav, NavItem } from "reactstrap";
import { FiTrash2 } from "react-icons/fi";
import axios from "axios";
import ButtonSection from "./ButtonSection";

export default function DynamicTab({ tabdata }) {
  const [activeTab, setActiveTab] = useState("transcription");

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const handleDelete = (id) => {
    console.log("Delete clicked for template ID:", id);
    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/api/formated_transcription_template/${id}`
      )
      .then(() => {
        console.log("Template deleted successfully");
      })
      .catch((error) => {
        console.error("Error deleting template:", error);
      });
  };

  return (
    <>
      <div>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={activeTab === "transcription" ? "active" : ""}
              onClick={() => toggleTab("transcription")}
            >
              Transcription
            </NavLink>
          </NavItem>

          {tabdata.map((it, index) => {
            return (
              <>
                <NavItem>
                  <NavLink
                    className={activeTab === it.id ? "active" : ""}
                    onClick={() => toggleTab(it.id)}
                  >
                    {it.heading}
                  </NavLink>
                </NavItem>
              </>
            );
          })}
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="transcription">
            <input type="hidden" id="transcript" />
            <div className="mt-2">
              <textarea
                cols="150"
                rows="20"
                className="form-control"
                id="contentToCopy"
              ></textarea>
            </div>
          </TabPane>
          {tabdata.map((it, index) => {
            const plainText = it.text.replace(/<[^>]*>/g, "");
            return (
              <>
                <TabPane tabId={it.id} key={it.id}>
                  <FiTrash2
                    style={{
                      float: "right",
                      marginBottom: "5px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleDelete(it.id)}
                  />
                  <div className="mt-2">
                    <textarea
                      cols="150"
                      rows="20"
                      className="form-control"
                      id={it.id}
                      value={plainText}
                    ></textarea>
                  </div>
                </TabPane>
              </>
            );
          })}
        </TabContent>
      </div>
      <div className="mt-2">
        <ButtonSection />
      </div>
    </>
  );
}
