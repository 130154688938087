import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  Navigate
} from "react-router-dom";
import Website from "../website/Website";
import Login from "../authentication/login/Login";
import Register from '../authentication/register/Register';
import Dashboard from '../admin/dashboard/Dashboard'
import Transcription from '../admin/transcription/Transcription';
import Subscription from '../admin/subscription/Subscription';
import PurchaseHistory from '../admin/purchase-history/PurchaseHistory';
import User from '../admin/user/User';
import ContactMessage from '../admin/contact-message/ContactMessage';
import Setting from '../admin/setting/Setting';
import Profile from '../admin/profile/Profile';
import Templates from "../admin/templates/Templates";
import TranscriptionHistory from "../admin/transcription/TranscriptionHistory";


export default function Layout() {

  const isAuthenticated = () => {
    const userDataObj = JSON.parse(localStorage.getItem("loggedInUser"));
    const token = userDataObj?.token;
    return !!token;
  };

  function ProtectedRoute({ children }) {
    if (!isAuthenticated()) {
      alert("Please log in first!");
      return <Navigate to="/login" replace />;
    }
    return children;
  }

  function RoutingSection() {
    return (
      <>
      <Routes>
        {/* Public Routes */}
        <Route path="/" element={<Website />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />

        {/* Protected Routes */}
        <Route
          path="/admin/dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/template"
          element={
            <ProtectedRoute>
              <Templates />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/subscription"
          element={
            <ProtectedRoute>
              <Subscription />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/transcription"
          element={
            <ProtectedRoute>
              <Transcription />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/session"
          element={
            <ProtectedRoute>
              <TranscriptionHistory />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/session/:id"
          element={
            <ProtectedRoute>
              <TranscriptionHistory />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/purchase-history"
          element={
            <ProtectedRoute>
              <PurchaseHistory />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/user"
          element={
            <ProtectedRoute>
              <User />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/contact-message"
          element={
            <ProtectedRoute>
              <ContactMessage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/setting"
          element={
            <ProtectedRoute>
              <Setting />
            </ProtectedRoute>
          }
        />

        {/* Fallback Route */}
        <Route path="*" element={<h2>404 - Page Not Found</h2>} />
      </Routes>
        {/* <Routes>
          <Route path="/" element={<Website />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/admin/dashboard" element={<Dashboard />} />
          <Route path="/admin/template" element={<Templates /> } />
          <Route path="/admin/subscription" element={<Subscription />} />
          <Route path="admin/transcription" element={<Transcription />} />
          <Route path="admin/session" element={<TranscriptionHistory />} />
          <Route path="admin/session/:id" element={<TranscriptionHistory />} />
          <Route path="/admin/purchase-history" element={<PurchaseHistory />} />
          <Route path="/admin/user" element={<User />} />
          <Route path="/admin/contact-message" element={<ContactMessage />} />
          <Route path="/admin/setting" element={<Setting />} />
          <Route path="*" element={<h2>404 - Page Not Found</h2>} />
        </Routes> */}
      </>
    );
  }

  return (
    <>
      <Router>
        <RoutingSection />
      </Router>
    </>
  );
}
