import React from 'react';

export default function ButtonSection() {
  const handlePrintLogic = () => {};

  const handlePdfLogic = () => {};

  const handleCopyLogic = () => {};
  return (
    <>
      <button
        id="copyButton"
        className="btn btn-outline-success"
        onClick={handleCopyLogic}
      >
        Copy
      </button>
      <button
        style={{ marginLeft: "5px", marginRight: "5px" }}
        target="_blank"
        className="btn btn-outline-success"
      >
        Print
      </button>
      <button
        target="_blank"
        className="btn btn-outline-success"
        onClick={handlePdfLogic}
      >
        PDF
      </button>
    </>
  );
}
