import React from 'react';

export default function Blog(){
    return(
        <>
              <section className="bg-body-emphasis" id="blog">
          <div className="container-small px-lg-7 px-xxl-3">
            <div className="mb-4 text-center text-sm-start">
              <h4 className="text-primary fa-9 mb-3">Blog</h4>
              <h2 className="fa-9">Latest articles</h2>
            </div>
            <p className="column-md-2 fa-9 mb-5">
              See the latest articles we published with this dashboard. Your
              customers will be happy to find all the latest posts in one place.
              This menu efficiently shows all related topics from search filters
              and provides the customers with what they need. Also you can just
              educate your customers about everything they need to know and
              follow to avail a service with you. This menu is the one to show
              them that.
            </p>
            <div className="row h-100 g-3 justify-content-center">
              <div className="col-sm-6 col-lg-3 mb-3 mb-md-0">
                <div className="card text-white dark__text-gray-1100 h-100">
                  <img
                    className="rounded-top h-100 object-fit-cover"
                    src="assets/img/blog/blog-1.png"
                    alt="..."
                  />
                  <div className="card-body rounded-top">
                    <div className="d-flex align-items-cente mb-3">
                      <div className="d-flex align-items-center me-3">
                        <a
                          className="btn-link text-decoration-none d-flex align-items-center"

                        >
                          <span className="fa-solid fa-eye text-body-quaternary me-1"></span>
                          <span className="text-body fs-10 lh-1">2563</span>
                        </a>
                      </div>
                      <div className="d-flex align-items-center me-3">
                        <a
                          className="btn-link text-decoration-none d-flex align-items-center"

                        >
                          <span className="fa-solid fa-heart text-body-quaternary me-1"></span>
                          <span className="text-body fs-10 lh-1">125</span>
                        </a>
                      </div>
                      <div className="d-flex align-items-center">
                        <a
                          className="btn-link text-decoration-none d-flex align-items-center"

                        >
                          <span className="fa-solid fa-comment text-body-quaternary me-1"></span>
                          <span className="text-body fs-10 lh-1">125</span>
                        </a>
                      </div>
                    </div>
                    <span className="badge badge-phoenix badge-phoenix-primary mb-2">
                      SEO
                    </span>
                    <h4 className="fa-9 mb-3 lh-sm line-clamp-2">
                      Top 10 ways to Ace SEO for your business
                    </h4>
                    <a
                      className="btn-link px-0 d-flex align-items-center fs-9 fw-bold"

                      role="button"
                    >
                      Read more
                      <span className="fa-solid fa-angle-right ms-2"></span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-3 mb-3 mb-md-0">
                <div className="card text-white dark__text-gray-1100 h-100">
                  <img
                    className="rounded-top h-100 object-fit-cover"
                    src="assets/img/blog/blog-2.png"
                    alt="..."
                  />
                  <div className="card-body rounded-top">
                    <div className="d-flex align-items-cente mb-3">
                      <div className="d-flex align-items-center me-3">
                        <a
                          className="btn-link text-decoration-none d-flex align-items-center"

                        >
                          <span className="fa-solid fa-eye text-body-quaternary me-1"></span>
                          <span className="text-body fs-10 lh-1">1256</span>
                        </a>
                      </div>
                      <div className="d-flex align-items-center me-3">
                        <a
                          className="btn-link text-decoration-none d-flex align-items-center"

                        >
                          <span className="fa-solid fa-heart text-body-quaternary me-1"></span>
                          <span className="text-body fs-10 lh-1">325</span>
                        </a>
                      </div>
                      <div className="d-flex align-items-center">
                        <a
                          className="btn-link text-decoration-none d-flex align-items-center"

                        >
                          <span className="fa-solid fa-comment text-body-quaternary me-1"></span>
                          <span className="text-body fs-10 lh-1">32</span>
                        </a>
                      </div>
                    </div>
                    <span className="badge badge-phoenix badge-phoenix-primary mb-2">
                      Marketing
                    </span>
                    <h4 className="fa-9 mb-3 lh-sm line-clamp-2">
                      Top 12 Marketing strategies you can take
                    </h4>
                    <a
                      className="btn-link px-0 d-flex align-items-center fs-9 fw-bold"

                      role="button"
                    >
                      Read more
                      <span className="fa-solid fa-angle-right ms-2"></span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-3 mb-3 mb-md-0">
                <div className="card text-white dark__text-gray-1100 h-100">
                  <img
                    className="rounded-top h-100 object-fit-cover"
                    src="assets/img/blog/blog-3.png"
                    alt="..."
                  />
                  <div className="card-body rounded-top">
                    <div className="d-flex align-items-cente mb-3">
                      <div className="d-flex align-items-center me-3">
                        <a
                          className="btn-link text-decoration-none d-flex align-items-center"

                        >
                          <span className="fa-solid fa-eye text-body-quaternary me-1"></span>
                          <span className="text-body fs-10 lh-1">142</span>
                        </a>
                      </div>
                      <div className="d-flex align-items-center me-3">
                        <a
                          className="btn-link text-decoration-none d-flex align-items-center"

                        >
                          <span className="fa-solid fa-heart text-body-quaternary me-1"></span>
                          <span className="text-body fs-10 lh-1">123</span>
                        </a>
                      </div>
                      <div className="d-flex align-items-center">
                        <a
                          className="btn-link text-decoration-none d-flex align-items-center"

                        >
                          <span className="fa-solid fa-comment text-body-quaternary me-1"></span>
                          <span className="text-body fs-10 lh-1">22</span>
                        </a>
                      </div>
                    </div>
                    <span className="badge badge-phoenix badge-phoenix-primary mb-2">
                      Marketing
                    </span>
                    <h4 className="fa-9 mb-3 lh-sm line-clamp-2">
                      The top 7 methods to improve as a marketer
                    </h4>
                    <a
                      className="btn-link px-0 d-flex align-items-center fs-9 fw-bold"

                      role="button"
                    >
                      Read more
                      <span className="fa-solid fa-angle-right ms-2"></span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-3 mb-3 mb-md-0">
                <div className="card text-white dark__text-gray-1100 h-100">
                  <img
                    className="rounded-top h-100 object-fit-cover"
                    src="assets/img/blog/blog-4.png"
                    alt="..."
                  />
                  <div className="card-body rounded-top">
                    <div className="d-flex align-items-cente mb-3">
                      <div className="d-flex align-items-center me-3">
                        <a
                          className="btn-link text-decoration-none d-flex align-items-center"

                        >
                          <span className="fa-solid fa-eye text-body-quaternary me-1"></span>
                          <span className="text-body fs-10 lh-1">2563</span>
                        </a>
                      </div>
                      <div className="d-flex align-items-center me-3">
                        <a
                          className="btn-link text-decoration-none d-flex align-items-center"

                        >
                          <span className="fa-solid fa-heart text-body-quaternary me-1"></span>
                          <span className="text-body fs-10 lh-1">325</span>
                        </a>
                      </div>
                      <div className="d-flex align-items-center">
                        <a
                          className="btn-link text-decoration-none d-flex align-items-center"

                        >
                          <span className="fa-solid fa-comment text-body-quaternary me-1"></span>
                          <span className="text-body fs-10 lh-1">112</span>
                        </a>
                      </div>
                    </div>
                    <span className="badge badge-phoenix badge-phoenix-primary mb-2">
                      Tech
                    </span>
                    <h4 className="fa-9 mb-3 lh-sm line-clamp-2">
                      Best places for a tech job in U.S
                    </h4>
                    <a
                      className="btn-link px-0 d-flex align-items-center fs-9 fw-bold"

                      role="button"
                    >
                      Read more
                      <span className="fa-solid fa-angle-right ms-2"></span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-center mt-6">
              <a className="btn btn-outline-primary">
                View All<span className="fa-solid fa-angle-right ms-2"></span>
              </a>
            </div>
          </div>
        </section>
        </>
    )
}