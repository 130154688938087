import React from 'react';

export default function Pricing(){
    return(
        <>
                    <section className="bg-body-emphasis pb-lg-6 pb-xl-8">
          <div
            className="bg-holder d-dark-none"
            style={{
              backgroundImage: "url(assets/img/bg/bg-5.png)",
              backgroundSize: "auto",
            }}
          ></div>

          <div
            className="bg-holder d-light-none"
            style={{
              backgroundImage: "url(assets/img/bg/bg-dark-5.png)",
              backgroundSize: "auto",
            }}
          ></div>

          <div
            className="bg-holder"
            style={{
              backgroundImage: "url(assets/img/bg/bg-left-5.png)",
              backgroundPosition: "left",
              backgroundSize: "auto",
            }}
          ></div>

          <div
            className="bg-holder"
            style={{
              backgroundImage: "url(assets/img/bg/bg-right-6.png)",
              backgroundPosition: "right",
              backgroundSize: "auto",
            }}
          ></div>
        </section>
        <section className="bg-body-emphasis pt-lg-0 pt-xl-8">
          <div
            className="bg-holder d-none d-md-block"
            style={{
              backgroundImage: "url(assets/img/bg/bg-left-15.png)",
              backgroundPosition: "left",
              backgroundSize: "auto",
            }}
          ></div>

          <div
            className="bg-holder d-none d-md-block"
            style={{
              backgroundImage: "url(assets/img/bg/bg-right-15.png)",
              backgroundPosition: "right",
              backgroundSize: "auto",
            }}
          ></div>

          <div className="container-small position-relative px-lg-7 px-xxl-3">
            <div className="mb-4 text-center text-sm-start">
              <h4 className="text-primary fa-9 mb-3">Pricing</h4>
              <h2 className="fa-9">Choose the best deal for you</h2>
            </div>
            <p className="column-md-2 fa-9 text-center text-sm-start">
              Entice your customers with Phoenix admin dashboard. Show your best
              deal in this section to help customers choose from your best
              offers and place them all in one place with this efficient
              template. If you are availing more than one offer to your
              customers, let them compare among them and search for what they
              need to get. Show offer details here and entice them to buy.
            </p>
            <div className="row pt-9 g-3 g-xl-0">
              <div className="col-md-6 col-xl-3">
                <div className="card h-100 rounded-end-xl-0 rounded-start">
                  <div className="card-body px-6">
                    <div className="px-5">
                      <div className="text-center pt-5">
                        <img
                          src="assets/img/icons/illustrations/pie.png"
                          width="48"
                          height="48"
                          alt=""
                        />
                        <h3 className="fa-9 my-4" style={{ fontWeight: 500 }}>
                          Starter
                        </h3>
                      </div>
                      <div className="text-center">
                        <h1 className="fw-semibold text-primary">
                          $<span className="fa-9">6</span>
                          <span className="text-body-emphasis fs-7 ms-1 fa-9">
                            USD
                          </span>
                        </h1>
                        <h5 className="mb-4 text-body"></h5>
                        <button className="btn btn-lg mb-6 w-100 btn-outline-primary">
                          Buy
                        </button>
                      </div>
                    </div>
                    <ul className="fa-ul pricing-list">
                      <li className="mb-4 d-flex align-items-center">
                        <span className="fa-li">
                          <span className="fas fa-check text-primary"></span>
                        </span>
                        <span
                          className="text-body-secondary fa-9"
                          style={{
                            "--phoenix-text-opacity": "1",
                          }}
                        >
                          Timeline
                        </span>
                      </li>
                      <li className="mb-4 d-flex align-items-center">
                        <span className="fa-li">
                          <span className="fas fa-check text-primary"></span>
                        </span>
                        <span
                          className="text-body-secondary fa-9"
                          style={{
                            "--phoenix-text-opacity": "1",
                          }}
                        >
                          Advanced Search
                        </span>
                      </li>
                      <li className="mb-4 d-flex align-items-center">
                        <span
                          className="text-body-secondary fa-9"
                          style={{
                            "--phoenix-text-opacity": ".5",
                          }}
                        >
                          Custom fields
                        </span>
                        <span className="badge badge-phoenix badge-phoenix-warning ms-2 fs-10 opacity-50">
                          New
                        </span>
                      </li>
                      <li className="mb-4 d-flex align-items-center">
                        <span
                          className="text-body-secondary fa-9"
                          style={{
                            "--phoenix-text-opacity": ".5",
                          }}
                        >
                          Task dependencies
                        </span>
                      </li>
                      <li className="mb-4 d-flex align-items-center">
                        <span
                          className="text-body-secondary fa-9"
                          style={{
                            "--phoenix-text-opacity": ".5",
                          }}
                        >
                          Private teams &amp; projects
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-xl-3">
                <div className="card h-100  rounded-top-0 rounded-xl-0 border border-2 border-primary mt-5 mt-md-0">
                  <div className="position-absolute d-flex flex-center bg-primary-subtle rounded-top py-1 end-0 start-0 badge-pricing">
                    <p className="text-primary-dark fa-9 mb-0">Most popular</p>
                  </div>
                  <div className="card-body px-6">
                    <div className="px-5">
                      <div className="text-center pt-5">
                        <img
                          src="assets/img/icons/illustrations/bolt.png"
                          width="48"
                          height="48"
                          alt=""
                        />
                        <h3 className="fa-9 my-4" style={{ fontWeight: 500 }}>
                          Team
                        </h3>
                      </div>
                      <div className="text-center">
                        <h1 className="fw-semibold text-primary">
                          $<span className="fa-9">12</span>
                          <span className="text-body-emphasis fs-7 ms-1 fa-9">
                            USD
                          </span>
                        </h1>
                        <h5 className="mb-4 text-body"></h5>
                        <button className="btn btn-lg mb-6 w-100 fa-9 btn-primary">
                          Buy
                        </button>
                      </div>
                    </div>
                    <ul className="fa-ul pricing-list">
                      <li className="mb-4 d-flex align-items-center">
                        <span className="fa-li">
                          <span className="fas fa-check text-primary"></span>
                        </span>
                        <span
                          className="text-body-secondary fa-9"
                          style={{
                            "--phoenix-text-opacity": "1",
                          }}
                        >
                          Timeline
                        </span>
                      </li>
                      <li className="mb-4 d-flex align-items-center">
                        <span className="fa-li">
                          <span className="fas fa-check text-primary"></span>
                        </span>
                        <span
                          className="text-body-secondary fa-9"
                          style={{
                            "--phoenix-text-opacity": "1",
                          }}
                        >
                          Advanced Search
                        </span>
                      </li>
                      <li className="mb-4 d-flex align-items-center">
                        <span className="fa-li">
                          <span className="fas fa-check text-primary"></span>
                        </span>
                        <span
                          className="text-body-secondary fa-9"
                          style={{
                            "--phoenix-text-opacity": "1",
                          }}
                        >
                          Custom fields
                        </span>
                        <span className="badge badge-phoenix badge-phoenix-warning ms-2 fs-10">
                          New
                        </span>
                      </li>
                      <li className="mb-4 d-flex align-items-center">
                        <span
                          className="text-body-secondary fa-9"
                          style={{
                            "--phoenix-text-opacity": ".5",
                          }}
                        >
                          Task dependencies
                        </span>
                      </li>
                      <li className="mb-4 d-flex align-items-center">
                        <span
                          className="text-body-secondary fa-9"
                          style={{
                            "--phoenix-text-opacity": ".5",
                          }}
                        >
                          Private teams &amp; projects
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-xl-3">
                <div className="card h-100 rounded-start rounded-start-xl-0 mt-5 mt-md-0">
                  <div className="card-body px-6">
                    <div className="px-5">
                      <div className="text-center pt-5">
                        <img
                          src="assets/img/icons/illustrations/edit.png"
                          width="48"
                          height="48"
                          alt=""
                        />
                        <h3 className="fa-9 my-4" style={{ fontWeight: 500 }}>
                          Business
                        </h3>
                      </div>
                      <div className="text-center">
                        <h1 className="fw-semibold text-primary">
                          $<span className="fa-9">23</span>
                          <span className="text-body-emphasis fs-7 ms-1 fa-9">
                            USD
                          </span>
                        </h1>
                        <h5 className="mb-4 text-body"></h5>
                        <button className="btn btn-lg mb-6 w-100 fa-9 btn-outline-primary">
                          Buy
                        </button>
                      </div>
                    </div>
                    <ul className="fa-ul pricing-list">
                      <li className="mb-4 d-flex align-items-center">
                        <span className="fa-li">
                          <span className="fas fa-check text-primary"></span>
                        </span>
                        <span
                          className="text-body-secondary fa-9"
                          style={{
                            "--phoenix-text-opacity": "1",
                          }}
                        >
                          Timeline
                        </span>
                      </li>
                      <li className="mb-4 d-flex align-items-center">
                        <span className="fa-li">
                          <span className="fas fa-check text-primary"></span>
                        </span>
                        <span
                          className="text-body-secondary fa-9"
                          style={{
                            "--phoenix-text-opacity": "1",
                          }}
                        >
                          Advanced Search
                        </span>
                      </li>
                      <li className="mb-4 d-flex align-items-center">
                        <span className="fa-li">
                          <span className="fas fa-check text-primary"></span>
                        </span>
                        <span
                          className="text-body-secondary fa-9"
                          style={{
                            "--phoenix-text-opacity": "1",
                          }}
                        >
                          Custom fields
                        </span>
                        <span className="badge badge-phoenix badge-phoenix-warning ms-2 fs-10">
                          New
                        </span>
                      </li>
                      <li className="mb-4 d-flex align-items-center">
                        <span className="fa-li">
                          <span className="fas fa-star text-primary"></span>
                        </span>
                        <span
                          className="text-body-secondary fa-9"
                          style={{
                            "--phoenix-text-opacity": "1",
                          }}
                        >
                          Task dependencies
                        </span>
                      </li>
                      <li className="mb-4 d-flex align-items-center">
                        <span
                          className="text-body-secondary fa-9"
                          style={{
                            "--phoenix-text-opacity": ".5",
                          }}
                        >
                          Private teams &amp; projects
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-xl-3 ps-xl-3">
                <div className="row g-0 h-100 justify-content-center">
                  <div className="col-xl-12">
                    <div className="card h-100 mt-5 mt-md-0">
                      <div className="card-body">
                        <div className="px-5">
                          <div className="text-center pt-5">
                            <img
                              src="assets/img/icons/illustrations/shield.png"
                              width="48"
                              height="48"
                              alt=""
                            />
                            <h3
                              className="fa-9 my-4"
                              style={{ fontWeight: 500 }}
                            >
                              Enterprise
                            </h3>
                          </div>
                          <div className="text-center">
                            <h1 className="fw-semibold text-primary">
                              $<span className="fa-9">40</span>
                              <span className="text-body-emphasis fa-9 fs-7 ms-1 ">
                                USD
                              </span>
                            </h1>
                            <h5 className="mb-4 text-body"></h5>
                            <button className="btn btn-lg mb-6 w-100 fa-9 btn-outline-primary">
                              Buy
                            </button>
                          </div>
                        </div>
                        <ul className="fa-ul pricing-list">
                          <li className="mb-4 d-flex align-items-center">
                            <span className="fa-li">
                              <span className="fas fa-check text-primary"></span>
                            </span>
                            <span
                              className="text-body-secondary fa-9"
                              style={{
                                "--phoenix-text-opacity": "1",
                              }}
                            >
                              Timeline
                            </span>
                          </li>
                          <li className="mb-4 d-flex align-items-center">
                            <span className="fa-li">
                              <span className="fas fa-check text-primary"></span>
                            </span>
                            <span
                              className="text-body-secondary fa-9"
                              style={{
                                "--phoenix-text-opacity": "1",
                              }}
                            >
                              Advanced Search
                            </span>
                          </li>
                          <li className="mb-4 d-flex align-items-center">
                            <span className="fa-li">
                              <span className="fas fa-check text-primary"></span>
                            </span>
                            <span
                              className="text-body-secondary fa-9"
                              style={{
                                "--phoenix-text-opacity": "1",
                              }}
                            >
                              Custom fields
                            </span>
                            <span className="badge badge-phoenix badge-phoenix-warning ms-2 fs-10">
                              New
                            </span>
                          </li>
                          <li className="mb-4 d-flex align-items-center">
                            <span className="fa-li">
                              <span className="fas fa-star text-primary"></span>
                            </span>
                            <span
                              className="text-body-secondary fa-9"
                              style={{
                                "--phoenix-text-opacity": "1",
                              }}
                            >
                              Task dependencies
                            </span>
                          </li>
                          <li className="mb-4 d-flex align-items-center">
                            <span className="fa-li">
                              <span className="fas fa-star text-primary"></span>
                            </span>
                            <span
                              className="text-body-secondary fa-9"
                              style={{
                                "--phoenix-text-opacity": "1",
                              }}
                            >
                              Private teams &amp; projects
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 text-center mt-8">
                <p className="fa-9 mb-5">
                  For Enterprise Solution with Managed SMTP, Custom API setup,
                  Dedicated Support, and more - <a href="#!"> Contact us</a>
                </p>
              </div>
            </div>
          </div>
        </section>
        </>
    )
}